import { getCookieOrUndefined, getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { ReactComponentRegistry } from "../../../cb/components/ReactRegistry"
import { getValidCountryData, isBlockedCountry } from "../../../cb/components/roomlist/countryFlagUtils"
import { modalAlert } from "../../alerts"
import { postCb } from "../../api"
import { Component } from "../../defui/component"
import { i18n } from "../../translation"
import { dom } from "../../tsxrender/dom"
import { optInFlagDisplaySettingsSave } from "../userActionEvents"
import type { ReactComponent } from "../../../cb/components/ReactRegistry"

const DEPRECATED_COOKIE_NAME = "rmFlgNtfnDis"
const cacheKey = (username: string): string => `rmFlgNtfnDis_${username}`

type RoomFlagNotificationProps = {
    roomName: string,
    isBroadcastPage: boolean,
    optInFlagDisplay: boolean,
    country: string | undefined,
}

export class RoomFlagNotificationWrapper extends Component<HTMLDivElement, RoomFlagNotificationProps> {
    public shouldDisplayNotification: boolean
    private roomName: string
    private countryData: { flagCode: string, flagName: string }
    private reactComponent: ReactComponent | undefined

    constructor(props: RoomFlagNotificationProps) {
        super("div", props)
    }

    private replaceCookieWithLocalStorage(username: string): void  {
        const cookieValue = getCookieOrUndefined(DEPRECATED_COOKIE_NAME)
        if (cookieValue === "1") {
            setLocalStorageWithExpiration(cacheKey(username), "1", { days: 60 })
        }
    }

    private cacheRoomFlagNotificationDismissed(): void {
        setLocalStorageWithExpiration(cacheKey(this.roomName), "1", { days: 60 })
    }

    private isEligibleForNotification(isValidCountry: boolean, optInFlagDisplay: boolean): boolean {
        this.replaceCookieWithLocalStorage(this.roomName)
        return isValidCountry && !optInFlagDisplay && getLocalStorageWithExpiration(cacheKey(this.roomName)) !== "1"
    }

    protected createBaseElement(_tagName: string, _props?: object): HTMLDivElement {
        return <div style={{ position: "relative" }} data-testid="room-flag-notification-container" />
    }

    protected initData(props: RoomFlagNotificationProps): void {
        this.roomName = props.roomName
        this.countryData = getValidCountryData(props.country?.toLowerCase())
        const isValidCountry = !isBlockedCountry(this.countryData.flagCode)

        this.shouldDisplayNotification = false
        if (props.isBroadcastPage && this.isEligibleForNotification(isValidCountry, props.optInFlagDisplay)) {
            this.shouldDisplayNotification = true
        } else if (props.optInFlagDisplay) {
            this.cacheRoomFlagNotificationDismissed()
        }
    }

    public renderNotification(props: {
        positioningEl: HTMLElement,
        onActivated: () => void,
        onCleanup: () => void,
    }): void {
        if (!this.shouldDisplayNotification) {
            return
        }

        const cleanUpNotification = () => {
            this.cacheRoomFlagNotificationDismissed()
            props.onCleanup()
            this.reactComponent?.dispose()
        }

        const optInToFlagDisplay = async (): Promise<void> => {
            const formData = new FormData()
            formData.append("opt_in_flag_display", "true")

            return postCb("api/ts/accounts/editoptinflagdisplay/", formData).then(() => {
                cleanUpNotification()
                props.onActivated()
            }).catch(() => {
                modalAlert(i18n.preferenceUpdateError)
            })
        }

        const RoomFlagNotification = ReactComponentRegistry.get("RoomFlagNotification")
        this.reactComponent = new RoomFlagNotification({
            countryCode: this.countryData.flagCode,
            countryName: this.countryData.flagName,
            onActivate: optInToFlagDisplay,
            onDismiss: cleanUpNotification,
            positioningEl: props.positioningEl,
        }, this.element)

        optInFlagDisplaySettingsSave.listen(optIn => optIn && cleanUpNotification())
    }
}

