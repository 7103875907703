import { showLoginOverlay } from "../../cb/ui/loginOverlay"
import { setAnonymousCallback } from "../auth"
import { deniedLoaded, profileLoaded } from "../context"
import { Component } from "../defui/component"
import { loadRoomRequest } from "../fullvideolib/userActionEvents"
import { GenderTabs } from "../theatermodelib/genderTabs"
import { loginOverlayRequest } from "../theatermodelib/userActionEvents"
import { DeniedContents } from "./deniedContents"
import { ProfileContents } from "./profileContents"
import type { IProfileContext } from "../../entrypoints/profile"
import type { IDeniedProfileContext } from "../../entrypoints/profile_denied"

export const isProfilePage = (): boolean => localIsProfilePage

let localIsProfilePage = false

export class ProfileRoot extends Component {
    genderTabs: GenderTabs
    profileContents: ProfileContents | DeniedContents

    constructor(roomName: string) {
        super()
        localIsProfilePage = true
        const main = document.getElementById("main") as HTMLDivElement
        main.style.font = "1em 'UbuntuRegular',Arial,Helvetica,sans-serif"
        main.appendChild(this.element)

        this.genderTabs = this.addChild(new GenderTabs(roomName))

        profileLoaded.listen(context => {
            this.createRoomContents(context)
        })

        deniedLoaded.listen(context => {
            this.createDeniedContents(context)
        })

        // When user clicks on a room card in MRLT tab, send them to chat page (different entrypoint)
        loadRoomRequest.listen((username) => {
            window.location.pathname = `/${username}/`
        })

        this.element.style.position = "static"
        this.element.style.marginRight = "15px"
        this.element.style.boxSizing = "border-box"
        this.element.style.minHeight = "400px"
        this.element.focus()
        this.setupLoginOverlay()
        this.repositionChildrenRecursive()
        this.afterDOMConstructedIncludingChildren()
    }

    private setupLoginOverlay(): void {
        loginOverlayRequest.listen((fromFeature) => {
            showLoginOverlay({ fromFeature: fromFeature })
        })
        setAnonymousCallback(() => {
            loginOverlayRequest.fire(true)
        })
    }

    private createRoomContents(context: IProfileContext): void {
        const profileContents = new ProfileContents(context)
        this.profileContents = this.addChild(profileContents)

        profileContents.topSectionWrapper.style.position = "fixed"
        profileContents.topSectionWrapper.style.top = "999999px"
    }

    private createDeniedContents(context: IDeniedProfileContext): void {
        this.profileContents = this.addChild(new DeniedContents(context))
    }
}
