import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addColorClass, removeColorClass } from "../cb/colorClasses"
import { PhotoVideos } from "../cb/photovideos/photoVideos"
import { CollapsibleComponent, ExpandableDropDownMenu } from "../cb/ui/expandableDropDownMenu"
import { Wrapper } from "../cb/ui/wrapper"
import { Component } from "./defui/component"
import { applyStyles } from "./DOMutils"

interface IRoomTab {
    anchor: CollapsibleComponent<HTMLAnchorElement>
    instance: ITabInstance
}

export interface ITabInstance extends Component {
    show(id?: number, callback?: () => void): void
    hide(): void
    checkBeforeSwitch(switchCallback: () => void): void
}

export class RoomTabs extends Component {
    protected activeTabName: string
    protected defaultTab: string // must be set
    private tabs: Record<string, IRoomTab|undefined> = {}
    public tabBar: Wrapper
    protected isAnonymous: boolean
    protected tabStorageKey = "selectedRoomTab"
    protected tabsWrapper: Wrapper

    constructor() {
        super()

        this.element.id = "roomTabs"
        this.element.style.position = "static"
        this.element.style.borderRadius = "4px 4px 0 0"
        this.element.style.boxSizing = "border-box"
        this.element.style.overflow = "visible"
        this.element.style.paddingBottom = "1px"
        this.element.dataset.testid = "room-tabs"

        this.tabBar = new Wrapper()
        this.tabBar.element.dataset["testid"] = "room-tab-bar"
        addColorClass(this.tabBar.element, "tabBar")
        applyStyles(this.tabBar, {
            height: "30px",
            padding: "8px 4px",
            boxSizing: "border-box",
            overflow: "visible",
            borderRadius: "2px 2px 0 0",
        })
        this.addChild(this.tabBar)

        this.tabsWrapper = new Wrapper()
        applyStyles(this.tabsWrapper, {
            height: "24px",
            top: "-2px",
        })
        this.tabBar.addChild(this.tabsWrapper)
    }

    protected addTab(text: string, translatedText: string, tabComponent: ITabInstance): IRoomTab {
        const tab = this.createBaseTab(text, translatedText, tabComponent)
        this.tabsWrapper.addChild(tab.anchor)
        this.tabs[text] = tab
        tab.anchor.element.dataset["testid"] = `room-tab-${text}`
        return tab
    }

    protected hide(text: string): void {
        const thisTab = this.tabs[text]
        if (thisTab !== undefined) {
            thisTab.anchor.element.style.display = "none"
        }
    }

    protected navigate(text: string): void {
        this.makeTabActive(text, undefined)
        this.setTabLocalStorage(this.activeTabName)
    }

    private createBaseTab(text: string, translatedText: string, tabComponent: ITabInstance): IRoomTab {
        const link = new CollapsibleComponent<HTMLAnchorElement>("a")
        addColorClass(link.element, "tabLink")
        link.element.href = "#"
        link.element.innerText = translatedText
        this.styleTabLink(link, text)

        if (text === this.activeTabName) {
            this.styleTabForOpen(link)
        }

        const thisTab = {
            anchor: link,
            instance: tabComponent,
        }

        this.tabs[text] = thisTab
        tabComponent.hide()
        this.addChild(tabComponent)

        link.element.onclick = (e) => {
            this.navigate(text)
        }

        link.onCollapseEvent.listen(collapsed => {
            if (text === this.activeTabName) {
                this.styleTabForOpen(link)
            } else {
                this.styleTabForClose(link)
            }
            if (collapsed) {
                addColorClass(link, "tabCollapsed")
                link.element.style.cssFloat = ""
                link.element.style.marginTop = "2px"
            } else {
                removeColorClass(link, "tabCollapsed")
                link.element.style.cssFloat = "left"
                link.element.style.marginTop = "0"
            }
        })

        return thisTab
    }

    protected styleTabLink(link: Component, text: string, startHidden?: boolean): void {
        link.element.style.width = ""
        link.element.style.height = "17px"
        link.element.style.borderRadius = "4px 4px 0 0"
        link.element.style.textDecoration = "none"
        link.element.style.marginRight = "2px"
        link.element.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        link.element.style.fontSize = "13px"
        link.element.style.padding = "4px 8px 4px 8px"
        link.element.style.position = "relative"
        link.element.style.cssFloat = "left"
        link.element.style.display = "block"

        if (startHidden === true) {
            link.element.style.display = "none"
        }

        link.element.onpointerenter = (e) => {
            if (e.pointerType !== "mouse") {
                return
            }
            this.styleTabForOpen(link)
        }

        link.element.onpointerleave = (e) => {
            if (e.pointerType !== "mouse") {
                return
            }
            if (this.activeTabName !== text) {
                this.styleTabForClose(link)
            }
        }
    }

    protected checkTabLocalStorage(): string | undefined {
        if (isLocalStorageSupported()) {
            const storedTabText = window.localStorage.getItem(this.tabStorageKey)
            if (storedTabText !== null) {
                if (this.tabs[storedTabText] !== undefined) {
                    return storedTabText
                }
            }
        }
        return undefined
    }

    protected setTabLocalStorage(text: string): void {
        if (isLocalStorageSupported()) {
            window.localStorage.setItem(this.tabStorageKey, text)
        }
    }

    private styleTabForOpen(c: Component): void {
        addColorClass(c.element, "tabOpen")
    }

    private styleTabForClose(c: Component): void {
        removeColorClass(c.element, "tabOpen")
    }

    protected hideTabAnchor(key: string): void {
        const tab = this.tabs[key]
        if (tab === undefined) {
            return
        }
        if (this.activeTabName === key) {
            // if trying to hide active tab, switch to default tab
            this.makeTabActive(this.defaultTab)
        }
        tab.anchor.hideElement()
    }

    protected showTabAnchor(key: string): void {
        const tab = this.tabs[key]
        if (tab === undefined) {
            return
        }
        tab.anchor.showElement()
    }

    protected makeTabActive(text: string, showArgument?: number, callback?: () => void): void {
        if (this.activeTabName === text) {
            if (callback !== undefined) {
                callback()
            }
            return
        }
        const thisTab = this.tabs[text]
        if (thisTab !== undefined) {
            thisTab.anchor.element.style.display = "block"
        }
        if (thisTab === undefined || !thisTab.anchor.isShown()) {
            return
        }
        for (const key of Object.keys(this.tabs)) {
            const tab = this.tabs[key]
            if (tab === undefined) { // should never happen, but here to appease the compiler
                continue
            }
            this.styleTabForClose(tab.anchor)
            tab.instance.hide()
        }
        this.styleTabForOpen(thisTab.anchor)
        if (
            thisTab.instance instanceof PhotoVideos
        ) {
            thisTab.instance.show(showArgument)
        } else {
            thisTab.instance.show(undefined, callback)
        }
        this.activeTabName = text
    }

    protected getActiveTab(): IRoomTab | undefined {
        return this.tabs[this.activeTabName]
    }

    protected constructExpandableDropDownMenu(): ExpandableDropDownMenu {
        const expandableDropDownMenu = new ExpandableDropDownMenu()
        this.styleTabLink(expandableDropDownMenu, "", false)
        applyStyles(expandableDropDownMenu, { display: "inline-block", height: "24px", width: "32px" })
        addColorClass(expandableDropDownMenu, "tabLink")
        expandableDropDownMenu.hideElement()
        applyStyles(expandableDropDownMenu.dropDown, {
            padding: "10px 1px", borderWidth: "1px",
            borderStyle: "solid", 
        })
        addColorClass(expandableDropDownMenu.dropDown, "room-tabs-dropdown-border")
        expandableDropDownMenu.setSpacerWidth(15)
        return expandableDropDownMenu
    }
}
