import { ArgJSONMap , usernameTitleCase } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { getCb, normalizeResource } from "../api"
import { isAnonymous } from "../auth"
import { profileLoaded, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { i18n } from "../translation"
import type { IRoomDossier } from "../roomDossier"
import type { ITabInstance } from "../roomTabs"

interface IShareResponse {
    tourRoomUrl: string
    tourTopUrl: string
    embedRoomUrl: string
    embedTopUrl: string
}

export class ShareTab extends Component implements ITabInstance {
    private loadingMessage: HTMLSpanElement = document.createElement("span")
    private header: HTMLDivElement = document.createElement("div")
    private shareRows: HTMLDivElement[] = []
    private currentDossier: IRoomDossier

    constructor() {
        super()
        this.element.id = "shareTab"
        addColorClass(this.element, colorClass.defaultColor)
        this.element.style.minHeight = "395px"
        this.element.style.padding = "15px"
        this.element.style.width = "100%"
        this.element.style.boxSizing = "border-box"
        this.element.style.position = "static"

        this.loadingMessage.style.display = "block"
        this.loadingMessage.style.fontSize = "12px"
        this.loadingMessage.textContent = "loading..."

        this.element.appendChild(this.loadingMessage)

        const header = document.createElement("div")
        const desc = document.createElement("div")
        const please = document.createElement("div")
        header.textContent = i18n.tokenLinkCodesText
        desc.textContent = i18n.earnTokensForRegisteredUsers
        please.textContent = i18n.linkCodeMessage

        addColorClass(header, "title")
        header.style.fontSize = "14.4115px"
        header.style.lineHeight = "20.1761px"
        header.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        header.style.marginBottom = "20px"
        header.dataset.testid = "share-title"
        desc.style.fontSize = "14.4115px"
        desc.style.lineHeight = "20.1761px"
        desc.style.margin = "14.4115px 0"
        desc.dataset.testid = "share-text"
        please.style.marginBottom = "12.0108px"
        please.style.fontSize = "12.0108px"
        please.style.lineHeight = "16.8px"
        please.style.fontWeight = "bold"
        please.dataset.testid = "share-subtext"

        this.header.appendChild(header)
        this.header.appendChild(desc)
        this.header.appendChild(please)

        this.element.appendChild(this.header)

        profileLoaded.listen(context => {
            this.currentDossier = context.dossier
            this.loadingMessage.style.display = "block"
            this.header.style.display = "none"
        })

        roomLoaded.listen(context => {
            this.currentDossier = context.dossier
            this.loadingMessage.style.display = "block"
            this.header.style.display = "none"
            this.clean()

            // Refresh the pane if we're visible during a room change, to match usernames.
            if (this.element.style.display === "block") { this.show() }
        })
    }

    private loadContent(): Promise<IShareResponse> {
        return new Promise((resolve, reject) => {
            getCb(`api/ts/chat/share/?room=${this.currentDossier.room}`).then(response => {
                const parsed = new ArgJSONMap(response.responseText)
                const resolvedValue: IShareResponse = {
                    tourRoomUrl: parsed.getString("tourroomurl"),
                    tourTopUrl: parsed.getString("tourtopurl"),
                    embedRoomUrl: parsed.getString("embedroomurl"),
                    embedTopUrl: parsed.getString("embedtopurl"),
                }
                parsed.logUnusedDebugging("ShareTab")
                resolve(resolvedValue)
            }).catch(reject)
        })
    }

    private clean(): void {
        this.shareRows.forEach(row => {
            this.element.removeChild(row)
        })
        this.shareRows = []
    }

    private handleShareResponse(response: IShareResponse): void {
        const createShareLink = (labelText: string, inputValue: string): void => {
            const row = document.createElement("div")
            const label = document.createElement("label")
            const urlInput = document.createElement("input")
            row.style.margin = "12.0108px 0"
            row.style.lineHeight = "16.8px"
            urlInput.type = "text"
            urlInput.value = inputValue
            addColorClass(label, "link")
            label.textContent = labelText
            label.style.display = "block"
            label.style.fontSize = "12.012px"
            label.style.lineHeight = "16.8px"
            label.style.fontWeight = "bold"
            label.style.cursor = "pointer"
            label.style.width = "375px"
            label.dataset.testid = "share-link"
            label.onclick = () => {
                urlInput.select()
            }
            addColorClass(urlInput, "urlInput")
            urlInput.style.display = "block"
            urlInput.style.borderRadius = "4px"
            urlInput.style.fontSize = "12px"
            urlInput.style.fontFamily = "Arial, Helvetica, sans-serif"
            urlInput.style.verticalAlign = "middle"
            urlInput.style.width = "375px"
            urlInput.style.marginTop = "4px"
            urlInput.style.height = "20px"
            urlInput.style.lineHeight = "18px"
            urlInput.style.borderWidth = "1px"
            urlInput.style.borderStyle = "solid"
            urlInput.style.padding = "2px 4px"
            urlInput.readOnly = true
            urlInput.dataset.testid = "share-url-input"
            urlInput.onclick = () => {
                urlInput.select()
            }
            this.shareRows.push(row)
            row.appendChild(label)
            row.appendChild(urlInput)
            this.element.appendChild(row)
        }

        const iframeAttrs = "height=528 width=850 style='border: none;'"
        const roomNameTitled = usernameTitleCase(this.currentDossier.room)
        createShareLink(
            `${i18n.shareCamText(roomNameTitled)}:`,
            response.tourRoomUrl,
        )
        createShareLink(
            `${i18n.shareText} Chaturbate.com:`,
            response.tourTopUrl,
        )
        createShareLink(
            `${i18n.embedCamText(roomNameTitled)}:`,
            `<iframe src='${response.embedRoomUrl}' ${iframeAttrs}></iframe>`,
        )
        createShareLink(
            `${i18n.embedCBTopCamText}:`,
            `<iframe src='${response.embedTopUrl}' ${iframeAttrs}></iframe>`,
        )

        const affiliateStatsDetails = document.createElement("div")
        const link = document.createElement("a")
        addColorClass(link, "link")
        link.href = normalizeResource("/affiliates/stats/")
        link.textContent = i18n.affiliateProgramStatsText
        link.dataset.testid = "affiliate-stats-link"
        link.onmouseenter = () => {
            link.style.textDecoration = "underline"
        }
        link.onmouseleave = () => {
            link.style.textDecoration = "none"
        }
        addColorClass(affiliateStatsDetails, colorClass.defaultColor)
        affiliateStatsDetails.textContent = i18n.affiliateStatsDetails
        affiliateStatsDetails.style.fontSize = "12.012px"
        affiliateStatsDetails.style.lineHeight = "16.8px"
        affiliateStatsDetails.style.marginTop = "30px"
        affiliateStatsDetails.dataset.testid = "share-more-details"
        affiliateStatsDetails.appendChild(link)
        this.shareRows.push(affiliateStatsDetails)
        this.element.appendChild(affiliateStatsDetails)
    }

    show(): void {
        this.loadingMessage.style.display = "block"
        this.header.style.display = "none"
        this.element.style.display = "block"
        this.clean()
        if (isAnonymous()) {
            return
        }
        this.loadContent().then(response => {
            this.loadingMessage.style.display = "none"
            this.header.style.display = "block"
            this.handleShareResponse(response)
        }).catch(() => {})
    }

    hide(): void {
        this.element.style.display = "none"
        this.loadingMessage.style.display = "none"
    }

    checkBeforeSwitch(switchCallback: () => void): void {
        switchCallback()
    }
}
