import { addColorClass } from "../../cb/colorClasses"
import { CollapsibleComponent } from "../../cb/ui/expandableDropDownMenu"
import { normalizeResource } from "../api"
import { applyStyles, hoverEvent } from "../DOMutils"

export class GenderTabHandle extends CollapsibleComponent<HTMLAnchorElement> {
    public isActiveRoom = false

    constructor(text: string, href?: string, isActiveRoom = false) {
        super("a")
        this.element.classList.add("gender-tab")
        this.element.dataset["paction"] = "TopTab"
        addColorClass(this.element, "tabElement")
        this.isActiveRoom = isActiveRoom
        if (this.isActiveRoom) {
            this.element.classList.add("active")
        }
        if (href !== undefined) {
            this.element.href = normalizeResource(href)
        }
        this.element.innerText = text
        applyStyles(this.element, GenderTabHandle.genderTabStyles)
        this.updateStyles()
        hoverEvent(this.element, { ignoreTouch: true }).listen(hover => {
            this.updateStyles()
            if (hover && !this.isActiveRoom) {
                this.element.classList.add("hover")
            } else {
                this.element.classList.remove("hover")
            }
        })
        this.onCollapseEvent.listen(() => {
            this.updateStyles()
        })
    }

    updateStyles(): void {
        if (this.isActiveRoom) {
            addColorClass(this.element, "activeRoom")
            applyStyles(this.element, {
                fontSize: "15px",
                padding: "4px 11px 4px",
                maxWidth: "180px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "default",
                position: "static",
            })
        } else {
            addColorClass(this.element, "inactiveRoom")
        }
        addColorClass(this.element, "tabElementLink")
    }

    public static genderTabStyles: CSSX.Properties = {
        width: "",
        position: "relative",
        display: "inline-block",
        cssFloat: "left",
        boxSizing: "border-box",
        padding: "5px 11px 4px",
        marginRight: "2px",
        fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "4px 4px 0 0",
        fontSize: "13px",
        textDecoration: "none",
        cursor: "pointer",
        lineHeight: "1.3",
    }
}
