import { t } from "@lingui/macro"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, colorClass, removeColorClass } from "../../../cb/colorClasses"
import { addEventListenerPoly } from "../../addEventListenerPolyfill"
import { modalAlert, modalConfirm } from "../../alerts"
import { getCb, normalizeResource } from "../../api"
import { ignoreCatch } from "../../promiseUtils"
import { i18n } from "../../translation"
import { buildQueryString } from "../../urlUtil"
import { BaseRoomTab } from "./baseRoomTab"
import type { IInitialBroadcasterDossier } from "../../../entrypoints/broadcast"
import type { IProfileContext } from "../../../entrypoints/profile"

interface ITableColumns {
    header: string
    colName: string
    width: string
}

type ITokenStatsData = Record<string, string | number | HTMLElement>

interface ITransaction {
    date: string
    tokens: number
    tokenBalance: number
    description: string
    note: string
    username: string
    message: string
    cashAmount: string
    desiredTokenChange: number
    approved: boolean
    broadcasterUsername: string
    viewerUsername: string
    memberUsername: string
    subscriptionUsername: string
    photoVideoSetName: string
    photoVideoSetUser: string
    socialMediaName: string
    socialMediaOwner: string
    purchaser: string
    id: number
    isPremium: boolean
    isFanDiscounted: boolean
}

function parseTransaction(data: ArgJSONMap): ITransaction {
    return {
        date: data.getString("date"),
        tokens: data.getNumber("tokens"),
        tokenBalance: data.getNumber("token_balance"),
        description: data.getString("description"),
        note: data.getString("note"),
        username: data.getString("username"),
        message: data.getString("message"),
        cashAmount: data.getString("cash_amount"),
        desiredTokenChange: data.getNumber("desired_token_change"),
        approved: data.getBoolean("approved"),
        broadcasterUsername: data.getString("broadcaster_username"),
        viewerUsername: data.getString("viewer_username"),
        memberUsername: data.getString("member_username"),
        subscriptionUsername: data.getString("subscription_username"),
        photoVideoSetName: data.getString("photovideo_set_name"),
        photoVideoSetUser: data.getString("photovideo_set_user"),
        socialMediaName: data.getString("socialmedia_name"),
        socialMediaOwner: data.getString("socialmedia_owner"),
        purchaser: data.getString("purchaser"),
        id: data.getNumber("id"),
        isPremium: data.getBoolean("is_premium", false, false),
        isFanDiscounted: data.getBoolean("is_fan_discounted", false, false),
    }
}

interface ITokenStatsTabData {
    domain: string,
    token_balance: number,
    cashout_time_left: string,
    transactions: ITokenStatsData[],
    txns_fully_loaded: boolean,
    periods?: ITokenStatsData[],
    cashouts?: ITokenStatsData[],
    cashouts_fully_loaded: boolean,
}

export class TokenStatsTab extends BaseRoomTab<ITokenStatsTabData> {
    private isLoading = false
    private siteDomain: string
    private cashPage = 0
    private hasReachedEndOfTransactions = false
    private hasReachedEndOfCashouts = false
    private maxTransactionID: number | undefined
    private activityTable: HTMLTableElement
    private earningsTable: HTMLTableElement
    private cashoutsTable: HTMLTableElement
    private activityTableBody: HTMLTableSectionElement
    private earningsTableBody: HTMLTableSectionElement
    private cashoutTableBody: HTMLTableSectionElement
    private activityColumns: ITableColumns[] = [
        { header: i18n.dateText, colName: "date", width: "25%" },
        { header: i18n.actionText, colName: "description", width: "50%" },
        { header: i18n.tokensCC, colName: "tokens", width: "10%" },
        { header: i18n.tokenBalanceText, colName: "token_balance", width: "15%" },
    ]
    private earningsColumns: ITableColumns[] = [
        { header: i18n.dateText, colName: "date", width: "40%" },
        { header: i18n.tokensCC, colName: "tokens", width: "30%" },
    ]
    private cashoutColumns: ITableColumns[] = [
        { header: i18n.dateText, colName: "date", width: "33%" },
        { header: i18n.tokensCC, colName: "tokens", width: "33%" },
    ]

    constructor(private context: IProfileContext | IInitialBroadcasterDossier) {
        super()
        addColorClass(this.element, "TokenStatsTab")

        // Set the resource URL with the default param values, so "reload" will always be called with the default values
        this.setResourceUrl("api/ts/tipping/token-stats/", this.getResourceParams())
    }

    protected createContent(data: ITokenStatsTabData): void {
        this.activityTableBody = document.createElement("tbody")
        this.earningsTableBody = document.createElement("tbody")
        this.cashoutTableBody = document.createElement("tbody")
        this.activityTable = this.createTable(this.activityColumns, this.activityTableBody)
        this.earningsTable = this.createTable(this.earningsColumns, this.earningsTableBody)
        this.cashoutsTable = this.createTable(this.cashoutColumns, this.cashoutTableBody)

        this.siteDomain = data.domain
        this.cashPage = 0
        this.isLoading = false
        this.hasReachedEndOfTransactions = false
        this.hasReachedEndOfCashouts = false

        this.createSubNav()

        this.element.appendChild(this.createTokenBalance(data.token_balance))
        this.element.appendChild(document.createElement("hr"))

        if (this.context.isAgeVerified) {
            if (!this.context.hasStudio) {
                this.element.appendChild(this.createTransferTokens(data.cashout_time_left))
                this.element.appendChild(document.createElement("hr"))
            }
        } else {
            this.element.appendChild(this.createVerifyIdentity())
            this.element.appendChild(document.createElement("hr"))
        }

        this.createTables(data)
    }

    private createSubNav(): void {
        const subNav = document.createElement("div")
        this.element.appendChild(subNav)
        subNav.appendChild(this.createRefreshLink())
        if (this.context.isAgeVerified) {
            if (!this.context.hasStudio) {
                const detailedIncomeStatsButton = this.createLink(i18n.detailedIncomeStats, "/affiliates/stats/")
                detailedIncomeStatsButton.dataset.testid = "detailed-income-stats-button"

                const paymentInformationFormButton = this.createLink(i18n.paymentInformationForm, "/affiliates/payoutinfo/")
                paymentInformationFormButton.dataset.testid = "payment-information-form-button"

                subNav.appendChild(this.createSeparator())
                subNav.appendChild(detailedIncomeStatsButton)
                subNav.appendChild(this.createSeparator())
                subNav.appendChild(paymentInformationFormButton)
                subNav.appendChild(this.createLightText(` (${i18n.requiredForPayments})`))
            }

            const broadcasterVerificationFormButton = this.createLink(i18n.broadcasterVerificationForm, "/accounts/age_verification/")
            broadcasterVerificationFormButton.dataset.testid = "broadcaster-verification-form-button"
            subNav.appendChild(this.createSeparator())
            subNav.appendChild(broadcasterVerificationFormButton)
        }
        subNav.appendChild(this.createSeparator())
        const csvLink = this.createLink(i18n.downloadTransactionHistory, "/tipping/csv/history/")
        csvLink.dataset.testid = "download-transaction-form-button"
        let isLoading = false
        csvLink.onclick = (event: Event) => {
            event.preventDefault()
            if (isLoading) {
                modalAlert(i18n.pleaseWaitForFileToGenerate)
            } else {
                modalConfirm(i18n.download30Days, () => {
                    isLoading = true
                    window.location.href = normalizeResource("/tipping/csv/history")
                })
            }
        }
        subNav.appendChild(csvLink)
    }

    private createTables(data: ITokenStatsTabData): void {
        const transactionCallback = !data.txns_fully_loaded ? () => this.loadMoreTransactions() : undefined
        this.addTable(i18n.yourAccountsActivity, this.activityTable, this.activityTableBody, data.transactions, this.activityColumns, "account-activity-table", "account-activity", transactionCallback)

        if (this.context.isAgeVerified && data.periods !== undefined) {
            this.addTable(i18n.periodEarnings, this.earningsTable, this.earningsTableBody, data.periods, this.earningsColumns, "period-earnings-table", "period-earnings")
        }

        if (this.context.hasStudio && data.cashouts !== undefined) {
            const cashoutCallback = !data.cashouts_fully_loaded ? () => this.loadMoreCashouts() : undefined
            this.addTable(i18n.latestCashOuts, this.cashoutsTable, this.cashoutTableBody, data.cashouts, this.cashoutColumns, undefined, undefined, cashoutCallback)
            this.cashPage = 1
        }
    }

    private addTable(headerText: string, table: HTMLTableElement, tableBody: HTMLTableSectionElement, rows: ITokenStatsData[], columns: ITableColumns[], testid?: string, rowTestid?: string, callback?: () => Promise<boolean>) {
        const tableSection = this.createTableSection(headerText, table, callback)
        if (testid !== undefined) {
            tableSection.dataset.testid = testid
        }
        this.element.appendChild(tableSection)
        this.addTableRows(tableBody, rows, columns, rowTestid)
        this.element.appendChild(document.createElement("hr"))
    }

    protected parseData(rawData: string): ITokenStatsTabData {
        const dataMap = new ArgJSONMap(rawData)
        const parsedData = {
            domain: dataMap.getString("domain"),
            token_balance: dataMap.getNumber("token_balance"),
            cashout_time_left: dataMap.getString("cashout_time_left"),
            transactions: this.parseTransactions(dataMap.getList("transactions")),
            txns_fully_loaded: dataMap.getBoolean("txns_fully_loaded"),
            periods: this.parseEarnings(dataMap.getList("periods")),
            cashouts: this.parseCashouts(dataMap.getList("cashouts")),
            cashouts_fully_loaded: dataMap.getBoolean("cashouts_fully_loaded", true, false),
        }
        dataMap.logUnusedDebugging("TokenStatsTab")
        return parsedData
    }

    private parseTransactions(data: ArgJSONMap[] = []): ITokenStatsData[] {
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" }
        const parsedData: ITokenStatsData[] = []
        for (const transaction of data) {
            const parsedTransaction = parseTransaction(transaction)
            parsedData.push({
                "date": new Date(parsedTransaction.date).toLocaleString(undefined, options),
                "description": this.makeAction(parsedTransaction),
                "tokens": parsedTransaction.tokens,
                "token_balance": parsedTransaction.tokenBalance,
            })
            this.maxTransactionID = parsedTransaction.id - 1
        }
        return parsedData
    }

    private parseEarnings(earnings: ArgJSONMap[] = []): ITokenStatsData[] {
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric", timeZone: "UTC" }
        const data: ITokenStatsData[] = []
        for (const earning of earnings) {
            const start = new Date(earning.getString("periodStart")).toLocaleString(undefined, options)
            const end = new Date(earning.getString("periodEnd")).toLocaleString(undefined, options)
            data.push({
                "date": `${start} - ${end}`,
                "tokens": earning.getNumber("tokens"),
            })
        }
        return data
    }

    private parseCashouts(cashouts: ArgJSONMap[] = []): ITokenStatsData[] {
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" }
        const data: ITokenStatsData[] = []
        for (const cashout of cashouts) {
            const timestamp = cashout.getString("date")
            data.push({
                "date": new Date(timestamp).toLocaleString(undefined, options),
                "tokens": cashout.getNumber("tokens"),
            })
        }
        return data
    }

    private createLightText(text: string): HTMLElement {
        const container = document.createElement("span")
        addColorClass(container, "lightText")
        container.innerText = text

        return container
    }

    private createRefreshLink(): HTMLElement {
        const refreshLink = document.createElement("a")
        addColorClass(refreshLink, colorClass.hrefColor)

        refreshLink.onclick = (event: Event) => {
            event.preventDefault()
            this.load()
        }
        refreshLink.innerText = i18n.refreshStats
        refreshLink.style.cursor = "pointer"
        refreshLink.dataset.testid = "refresh-stats-link"

        return refreshLink
    }

    private createSeparator(): HTMLSpanElement {
        const separator = document.createElement("span")
        addColorClass(separator, "separator")
        separator.style.fontSize = "12px"
        separator.innerText = " | "
        return separator
    }

    private createLink(text: string, href: string): HTMLAnchorElement {
        const link = document.createElement("a")
        addColorClass(link, colorClass.hrefColor)

        link.innerText = text
        link.href = normalizeResource(href)
        return link
    }

    private createTokenBalance(numTokens: number): HTMLElement {
        const header = document.createElement("h2")
        header.innerText = `${i18n.tokenBalanceText}: `

        const balance = document.createElement("span")
        balance.innerText = String(numTokens)
        balance.style.textDecoration = "underline"

        header.appendChild(balance)
        if (this.context.hasStudio) {
            header.appendChild(document.createTextNode(` (${i18n.tokensAutoCashedAtMidnight})`))
        }

        balance.dataset.testid = "token-balance"
        return header
    }

    private createVerifyIdentity(): HTMLElement {
        const container = document.createElement("div")
        const pleaseVerify = document.createElement("p")
        pleaseVerify.innerText = `${i18n.verifyToEnableTokens}.`
        const linkContainer = document.createElement("p")
        linkContainer.appendChild(this.createLink(i18n.broadcasterVerificationForm, "/accounts/age_verification/"))

        container.appendChild(pleaseVerify)
        container.appendChild(linkContainer)

        return container
    }

    private createTransferTokens(timeLeft: string): HTMLElement {
        const container = document.createElement("div")
        const header = document.createElement("h2")
        header.innerText = i18n.transferTokens
        header.dataset.testid = "transfer-tokens-label"
        const transferLink = this.createLink(i18n.transferTokensToCash, "/tipping/cashout_tokens/")
        transferLink.dataset.testid = "transfer-tokens-to-cash-link"
        const info1 = document.createElement("p")
        info1.innerText = i18n.transferTokensDateInfo
        const info2 = document.createElement("p")
        info2.innerText = timeLeft

        container.appendChild(header)
        container.appendChild(transferLink)
        container.appendChild(info1)
        container.appendChild(info2)
        return container
    }

    private createTableSection(headerText: string, table: HTMLTableElement, callback?: () => Promise<boolean>): HTMLElement {
        const container = document.createElement("div")
        const header = document.createElement("h2")
        header.innerText = `${headerText}:`
        const tableContainer = this.createTableContainer()
        container.appendChild(header)
        container.appendChild(tableContainer)
        tableContainer.appendChild(table)

        if (callback !== undefined) {
            tableContainer.appendChild(this.createLoadMoreButton(callback))
        }

        return container
    }

    private createTableContainer(): HTMLElement {
        const container = document.createElement("div")
        container.style.width = "700px"
        container.style.height = "250px"
        container.style.overflow = "auto"
        return container
    }

    private createLoadMoreButton(callback: () => Promise<boolean>): HTMLButtonElement {
        const button = document.createElement("button")
        addColorClass(button, "loadMoreButton")
        button.dataset.testid = "load-more-stats"
        button.textContent = i18n.loadMore
        addEventListenerPoly("click", button, () => {
            button.textContent = i18n.loadingPlaceholderText
            addColorClass(button, "disabled")
            callback().then((fullyLoaded) => {
                if (fullyLoaded) {
                    button.style.display = "none"
                }
            }).catch(ignoreCatch).finally(() => {
                removeColorClass(button, "disabled")
                button.textContent = i18n.loadMore
            })
        })
        return button
    }

    private createTable(columns: ITableColumns[], tableBody: HTMLTableSectionElement): HTMLTableElement {
        const table = document.createElement("table")
        addColorClass(table, "tokenStatsTable")
        table.style.width = "675px"
        table.style.padding = "0px"
        table.style.margin = "0px"
        table.style.borderWidth = "1px"
        table.style.borderStyle = "solid"
        table.style.borderCollapse = "collapse"
        table.style.marginBottom = "8px"

        const headerRow = document.createElement("tr")
        addColorClass(headerRow, "rowHeader")
        headerRow.style.textAlign = "left"
        headerRow.style.lineHeight = "20px"

        for (const column of columns) {
            const header = document.createElement("th")
            header.style.borderWidth = "1px"
            header.style.borderStyle = "solid"
            header.innerText = column.header
            header.style.width = column.width
            header.style.fontWeight = "normal"
            headerRow.appendChild(header)
        }

        table.appendChild(tableBody)
        tableBody.appendChild(headerRow)
        return table
    }

    private createTableRow(isEven: boolean, testid?: string): HTMLElement {
        const row = document.createElement("tr")
        if (isEven) {
            addColorClass(row, "rowEven")
        } else {
            addColorClass(row, "rowOdd")
        }
        row.style.textAlign = "left"
        row.style.lineHeight = "20px"
        if (testid !== undefined) {
            row.dataset.testid = `${testid}-row`
        }
        return row
    }

    private addTableRows(tableBody: HTMLTableSectionElement, rows: ITokenStatsData[], columns: ITableColumns[], testid?: string): void {
        let childNodeCount = tableBody.childElementCount
        for (const row of rows) {
            const tableRow = this.createTableRow(childNodeCount % 2 === 1, testid)
            for (const column of columns) {
                const tableData = document.createElement("td")
                tableData.style.paddingLeft = "4px"
                tableData.style.borderLeftWidth = "1px"
                tableData.style.borderRightWidth = "1px"
                tableData.style.borderLeftStyle = "solid"
                tableData.style.borderRightStyle = "solid"
                tableData.style.width = column.width
                const data = row[column.colName]
                if (data instanceof HTMLElement) {
                    tableData.appendChild(data)
                } else {
                    tableData.textContent = String(data)
                }
                if (column.colName === "description") {
                    tableData.style.wordBreak = "break-all"
                }
                tableRow.appendChild(tableData)
            }
            tableBody.appendChild(tableRow)
            childNodeCount += 1
        }
    }

    private loadMoreCashouts(): Promise<boolean> {
        if (this.isLoading || this.hasReachedEndOfCashouts) {
            return Promise.resolve(this.hasReachedEndOfCashouts)
        }
        this.isLoading = true
        return getCb(`api/ts/tipping/token-stats/?${buildQueryString(this.getResourceParams())}`).then((response) => {
            this.isLoading = false
            const parsedResponse = new ArgJSONMap(response.responseText)
            const data = this.parseCashouts(parsedResponse.getList("cashouts"))
            if (data.length > 0) {
                this.cashPage += 1
                this.addTableRows(this.cashoutTableBody, data, this.cashoutColumns)
            }
            this.hasReachedEndOfCashouts = parsedResponse.getBoolean("cashouts_fully_loaded")
            return this.hasReachedEndOfCashouts
        }).catch((_) => {
            this.isLoading = false
            this.hasReachedEndOfCashouts = false
            modalAlert("There was an error loading more data")
            return false
        })
    }

    private loadMoreTransactions(): Promise<boolean> {
        if (this.isLoading || this.hasReachedEndOfTransactions) {
            return Promise.resolve(this.hasReachedEndOfTransactions)
        }
        this.isLoading = true
        return getCb(`api/ts/tipping/token-stats/?${buildQueryString(this.getResourceParams())}`).then((response) => {
            this.isLoading = false
            const parsedReponse = new ArgJSONMap(response.responseText)
            const data = this.parseTransactions(parsedReponse.getList("transactions"))
            if (data.length > 0) {
                this.addTableRows(this.activityTableBody, data, this.activityColumns)
            }
            this.hasReachedEndOfTransactions = parsedReponse.getBoolean("txns_fully_loaded")
            return this.hasReachedEndOfTransactions
        }).catch((_) => {
            this.isLoading = false
            this.hasReachedEndOfTransactions = false
            modalAlert("There was an error loading more data")
            return false
        })
    }

    private getResourceParams(): Record<string, string> {
        return {
            "max_transaction_id": this.maxTransactionID === undefined ? "" : String(this.maxTransactionID),
            "cashpage": String(this.cashPage),
        }
    }

    private makeAction(transaction: ITransaction): HTMLDivElement { // eslint-disable-line complexity
        const action = document.createElement("div")

        const actionsWithMesages = ["Tip sent", "Tip received"]

        // preserving the original logic for the time being
        const pickUsernameForFancClub = (transaction: ITransaction): string => {
            let username = ""
            if (transaction.broadcasterUsername !== "") {
                username = transaction.broadcasterUsername
            } else if (transaction.memberUsername !== "") {
                username = transaction.memberUsername
            } else if (transaction.subscriptionUsername !== "") {
                username = transaction.subscriptionUsername
            }

            return username
        }

        const basicSpan = (text: string): HTMLSpanElement => {
            const span = document.createElement("span")
            span.textContent = text
            return span
        }
        const htmlSpan = (htmlText: string): HTMLSpanElement => {
            const span = document.createElement("span")
            span.innerHTML = htmlText // eslint-disable-line @multimediallc/no-inner-html
            return span
        }
        const sanitizeText = (text: string): string => {
            const span = document.createElement("span")
            span.textContent = text
            return span.innerHTML // eslint-disable-line @multimediallc/no-inner-html
        }

        const actionsMap: Record<string, () => HTMLSpanElement> = {
            "Tip sent": () => htmlSpan(i18n.tipSentAction(transaction.username)),
            "Tip received": () => htmlSpan(i18n.tipReceivedAction(transaction.username)),
            "Tokens cashed out": () => basicSpan(this.context.hasStudio ? i18n.transferTokensToCash : i18n.transferTokensToCashWithValue(transaction.cashAmount)),
            "Tokens cashed to ad credit": () => basicSpan(this.context.hasStudio ? i18n.transferTokensToAdvertising : i18n.transferTokensToAdvertisingWithValue(transaction.cashAmount)),
            "Tipping Program tokens": () => basicSpan(i18n.referredMember),
            "Administrator adjusted your tokens": () => basicSpan(i18n.adminAdjustmentAction(transaction.note)),
            "Overlimit Purchased tokens": () => basicSpan(transaction.approved ? i18n.overSpendingLimitResolved(transaction.desiredTokenChange) : i18n.overSpendingLimitUnresolved(transaction.desiredTokenChange, `support@${this.siteDomain}`)),
            "Group show": () => htmlSpan(i18n.groupShowAction(transaction.broadcasterUsername)),
            "Private show": () => htmlSpan(i18n.privateShowAction(transaction.broadcasterUsername)),
            "Private show credited": () => htmlSpan(i18n.privateShowAction(transaction.viewerUsername)),
            "Group show credited": () => htmlSpan(i18n.groupShowAction(transaction.viewerUsername)),
            "Spy on private show": () => htmlSpan(i18n.spyShowAction(transaction.broadcasterUsername)),
            "Spy on private credited": () => htmlSpan(i18n.spyShowAction(transaction.viewerUsername)),
            "Fan club membership": () => htmlSpan(i18n.fanClubShowAction(pickUsernameForFancClub(transaction))),
            "Fan club credited": () => htmlSpan(i18n.fanClubShowAction(pickUsernameForFancClub(transaction))),
            "Photos/videos purchased": () => {
                const sanitizedName = sanitizeText(transaction.photoVideoSetName)
                return htmlSpan(i18n.purchasePhotoSet(sanitizedName, transaction.photoVideoSetUser))
            },
            "Photos/videos sold": () => {
                const sanitizedName = sanitizeText(transaction.photoVideoSetName)
                return htmlSpan(i18n.sellPhotoSet(sanitizedName, transaction.purchaser))
            },
            "Social media purchased": () => {
                const sanitizedName = sanitizeText(transaction.socialMediaName)
                return htmlSpan(i18n.purchaseSocialMediaFrom(sanitizedName, transaction.socialMediaOwner))
            },
            "Social media sold": () => {
                const sanitizedName = sanitizeText(transaction.socialMediaName)
                return htmlSpan(i18n.sellSocialMediaTo(sanitizedName, transaction.purchaser))
            },
            "Promoted room": () => htmlSpan(transaction.broadcasterUsername !== "" ? i18n.promotedRoomAction(transaction.broadcasterUsername) : i18n.promotedRoom),
        }

        const premiumActionsMap: Record<string, () => HTMLSpanElement> = {
            "Private show": () => htmlSpan(i18n.premiumPrivateShowAction(transaction.broadcasterUsername)),
            "Private show credited": () => htmlSpan(i18n.premiumPrivateShowAction(transaction.viewerUsername)),
        }

        const discountedFanItem = (username: string, url: string): string => {
            return t`Fan Discounted Spy Show: <a class='hrefColor' href=\"${url}\" rel='noopener' target='_blank'>${username}</a>`
        }

        const fanClubSpyActionsMap: Record<string, () => HTMLSpanElement> = {
            "Spy on private show": () => htmlSpan(discountedFanItem(transaction.broadcasterUsername, normalizeResource(`/${transaction.broadcasterUsername}`))),
            "Spy on private credited": () => htmlSpan(discountedFanItem(transaction.viewerUsername, normalizeResource(`/${transaction.viewerUsername}`))),
        } 

        if (transaction.description in actionsMap) {
            let descriptionElement = actionsMap[transaction.description]()
            if (transaction.isPremium) {
                descriptionElement = premiumActionsMap[transaction.description]()
            } else if (transaction.isFanDiscounted) {
                descriptionElement = fanClubSpyActionsMap[transaction.description]()
            }
            action.appendChild(descriptionElement)
            if (actionsWithMesages.includes(transaction.description) && transaction.message !== "") {
                const message = document.createElement("div")
                message.className = "transactionMessage"
                message.textContent = transaction.message
                action.appendChild(message)
            }
        } else {
            action.textContent = transaction.description
        }

        return action
    }
}
