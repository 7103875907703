import { ArgJSONMap , usernameTitleCase } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { ReactComponentRegistry } from "../../../cb/components/ReactRegistry"
import { pageContext } from "../../../cb/interfaces/context"
import { modalAlert, modalConfirm } from "../../alerts"
import { normalizeResource, postCb } from "../../api"
import { profileLoaded, roomLoaded } from "../../context"
import { featureFlagIsActive } from "../../featureFlag"
import { i18n } from "../../translation"
import { mediumPopupLinkFeatures, safeWindowOpen } from "../../windowUtils"
import { BaseRoomTab } from "./baseRoomTab"
import type { ReactComponent } from "../../../cb/components/ReactRegistry"
import type { IRoomDossier } from "../../roomDossier"

export interface ISocialMedia {
    expiration_string: string,
    expires: boolean,
    extend_label: string,
    extend_url: string,
    social_media: {
        extendable: boolean,
        format_duration: string,
        id: number,
        owner: string,
        title_name: string,
        token_price: number,
        is_fanclub_type: boolean,
    },
}

export interface IMembership {
    type: string,
    name: string,
    cost?: string,
    id?: number,
    username?: string,
    cancelled?: boolean,
    extendable?: boolean
    nonrecurring?: boolean,
    expired_at?: string,
    created_at?: string,
}

export interface IMembershipData {
    num_memberships: number,
    memberships: IMembership[],
    expired_memberships: IMembership[],
    active_social_media: ISocialMedia[],
    expired_social_media: ISocialMedia[],
}

export class MembershipsTab extends BaseRoomTab<IMembershipData> {
    private isSupporter = false
    protected hasRoomLoaded = false
    protected reactCancelMembership: ReactComponent
    protected wrapper: HTMLElement = document.createElement("td")
    constructor() {
        super()
        addColorClass(this.element, "MembershipsTab")

        // The reason we want to set this to relative is because the feedback form
        // has a SaveNotice component that pops up when a user submits feedback and
        // has a position: absolute styling.
        if (featureFlagIsActive("SupporterCancelFB")) {
            this.element.style.position = "relative"
        }
        profileLoaded.listen(context => {
            this.init(context.dossier)
        })

        roomLoaded.listen(context => {
            this.init(context.dossier)
        })
    }

    show(): void {
        super.show()
        if (featureFlagIsActive("SupporterCancelFB")) {
            const MembershipDurationContainer = ReactComponentRegistry.get("MembershipDurationContainer")
            this.reactCancelMembership = new MembershipDurationContainer({
                "cancelled": false,
                "membershipId": "",
                "expiredAt": "",
            }, this.wrapper)
        }
    }

    hide(): void {
        super.hide()
        if (featureFlagIsActive("SupporterCancelFB") && this.reactCancelMembership !== null && this.reactCancelMembership !== undefined) {
            this.reactCancelMembership.dispose()
        }
    }
    private init(dossier: IRoomDossier): void {
        this.hasRoomLoaded = true
        this.isSupporter = dossier.isSupporter
        this.setResourceUrl("api/ts/tipping/memberships/")
        if (this.showing) {
            this.load()
        }
    }

    protected parseData(rawData: string): IMembershipData {
        const dataMap = new ArgJSONMap(rawData)
        const parsedData = {
            num_memberships: dataMap.getNumber("num_memberships"),
            memberships: this.parseMembership(dataMap.getList("memberships")),
            expired_memberships: this.parseMembership(dataMap.getList("expired_memberships")),
            active_social_media: this.parseSocialMedia(dataMap.getList("active_social_media")),
            expired_social_media: this.parseSocialMedia(dataMap.getList("expired_social_media")),
        }
        dataMap.logUnusedDebugging("MembershipsTab")
        return parsedData
    }

    private parseMembership(memberships: ArgJSONMap[] | undefined): IMembership[] {
        if (memberships === undefined) {
            return []
        }
        const data: IMembership[] = []
        for (const member of memberships) {
            data.push({
                type: member.getString("type"),
                name: member.getString("name"),
                cost: member.getStringWithNumbers("cost", false),
                id: member.getNumberOrUndefined("id"),
                username: member.getStringOrUndefined("username"),
                cancelled: member.getBoolean("cancelled", undefined, false),
                extendable: member.getBoolean("extendable", undefined, false),
                nonrecurring: member.getBoolean("nonrecurring", undefined, false),
                expired_at: member.getStringOrUndefined("expired_at"),
                created_at: member.getStringOrUndefined("created_at"),
            })
        }
        return data
    }

    private parseSocialMedia(socialMedias: ArgJSONMap[] | undefined): ISocialMedia[] {
        if (socialMedias === undefined) {
            return []
        }
        const data: ISocialMedia[] = []
        for (const socialMedia of socialMedias) {
            const socialMediaKey = socialMedia.getMap("social_media")
            data.push({
                expiration_string: socialMedia.getString("expiration_string"),
                expires: socialMedia.getBoolean("expires"),
                extend_label: socialMedia.getString("extend_label"),
                extend_url: socialMedia.getString("extend_url"),
                social_media: {
                    extendable: socialMediaKey.getBoolean("extendable"),
                    format_duration: socialMediaKey.getString("format_duration"),
                    id: socialMediaKey.getNumber("id"),
                    owner: socialMediaKey.getString("owner"),
                    title_name: socialMediaKey.getString("title_name"),
                    token_price: socialMediaKey.getNumber("token_price"),
                    is_fanclub_type: socialMediaKey.getBoolean("is_fanclub_type"),
                },
            })
        }
        return data
    }

    protected createContent(data: IMembershipData): void {
        if (!this.hasRoomLoaded) {
            this.showLoadingMessage()
            return
        }
        if (data.num_memberships > 0) {
            this.element.appendChild(this.createHeader(i18n.activeMembershipsMessage(data.num_memberships)))
            this.element.appendChild(document.createElement("br"))
            this.element.appendChild(this.createMembershipsTable(data.memberships))
            this.element.appendChild(this.createSocialMediaTable(data.active_social_media))
        } else {
            this.element.appendChild(this.createEmptyMembership())
        }
        if (data.expired_memberships.length > 0) {
            this.element.appendChild(this.createHeader(i18n.expiredFanClubMemberships))
            this.element.appendChild(document.createElement("br"))
            this.element.appendChild(this.createExpiredFanClubTable(data.expired_memberships))
        }
        if (data.expired_social_media.length > 0) {
            this.element.appendChild(this.createHeader(i18n.expiredSocialMediaSubscriptions))
            this.element.appendChild(document.createElement("br"))
            this.element.appendChild(this.createExpiredSocialMediaTable(data.expired_social_media))
        }
    }

    protected createEmptyMembership(): HTMLElement {
        const container = document.createElement("div")
        const header = this.createHeader(i18n.noActiveMemberships)

        container.appendChild(header)
        container.appendChild(document.createElement("br"))
        if (!this.isSupporter) {
            const linkContainer = document.createElement("span")
            linkContainer.innerHTML = i18n.upgradeToSupporter(`/supporter/upgrade/?source=${pageContext.current.PurchaseEventSources["SUPPORTER_SOURCE_MEMBERSHIP_TAB"]}/`) // eslint-disable-line @multimediallc/no-inner-html
            container.appendChild(linkContainer)
        }
        return container
    }

    protected createHeader(text: string): HTMLElement {
        const header = document.createElement("h2")
        addColorClass(header, "membershipHeader")
        header.innerText = text
        header.style.fontSize = "1.266em"
        header.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        header.style.fontWeight = "normal"
        header.style.marginBottom = "2px"
        header.style.borderBottomWidth = "1px"
        header.style.borderBottomStyle = "solid"
        return header
    }

    protected createMembershipsTable(data: IMembership[]): HTMLElement {
        const table = document.createElement("table")
        for (const row of data) {
            const tr = document.createElement("tr")
            const membershipType = this.createTableData(true)
            const price = this.createTableData()
            const duration = this.createTableData()

            if (row.type === "fanclub") {
                membershipType.appendChild(this.createUsernameLink(row.username))
            }
            membershipType.appendChild(document.createTextNode(`${row.name}: `))

            if (row.nonrecurring === true) {
                price.innerText = `${row.cost} ${i18n.tokenOrTokensText(Number(row.cost), false)}`
            } else {
                price.innerText = i18n.monthlyCost(String(row.cost))
            }

            if (row.expired_at !== undefined) {
                const prefix = row.cancelled === true ? i18n.expires : i18n.renews
                duration.innerText = `${prefix}: ${new Date(row.expired_at).toLocaleDateString()}`
            }

            duration.appendChild(this.createDuration(row))

            table.appendChild(tr)
            tr.appendChild(membershipType)
            tr.appendChild(price)
            if (featureFlagIsActive("SupporterCancelFB") && row.type === "supporter") {
                this.reactCancelMembership.update({ "cancelled": row.cancelled, "membershipId": String(row.id), "expiredAt": row.expired_at })
                tr.appendChild(this.wrapper)
            } else {
                tr.appendChild(duration)
            }
        }
        return table
    }

    private createDuration(row: IMembership): HTMLElement {
        const container = document.createElement("div")
        if (Boolean(row.extendable)) {
            const extendLink = document.createElement("a")
            addColorClass(extendLink, colorClass.hrefColor)
            extendLink.target = "_blank"
            extendLink.href = normalizeResource(`/fanclub/join/${row.username}/`)
            extendLink.innerText = i18n.extendThreeMonths
            extendLink.style.display = "block"
            container.appendChild(extendLink)
        }
        const cantRenewContainer = document.createElement("div")
        addColorClass(cantRenewContainer, "membershipLightText")
        cantRenewContainer.innerText = `(${i18n.cantRenew})`
        container.appendChild(cantRenewContainer)
        if (Boolean(row.extendable)) {
            cantRenewContainer.style.display = "none"
        }

        const cancelContainer = document.createElement("div")
        container.appendChild(cancelContainer)

        const cancelLink = document.createElement("a")
        addColorClass(cancelLink, colorClass.hrefColor)
        cancelLink.href = "#"
        cancelLink.innerText = i18n.cancelText.toLocaleLowerCase()
        cancelContainer.appendChild(cancelLink)
        let confirmMessage = ""
        let errorMessage = ""
        if (row.type === "fanclub") {
            confirmMessage = i18n.fanclubCancelConfirmMessage(row.username !== undefined ? row.username : "")
            errorMessage = i18n.fanclubCancelErrorMessage
        } else if (row.type === "supporter") {
            confirmMessage = i18n.supporterCancelConfirmMessage
            errorMessage = i18n.supporterCancelErrorMessage
        }
        cancelLink.onclick = (event: Event) => {
            event.preventDefault()
            modalConfirm(confirmMessage, () => {
                postCb("tipping/memberships_panel/", { id: String(row.id) }).then((response) => {
                    const cancelled = response.responseText.split(",")[1]
                    if (cancelled === "True") {
                        if (!Boolean(row.extendable)) {
                            cantRenewContainer.style.display = "block"
                        }
                        cancelContainer.style.display = "none"
                    } else {
                        cantRenewContainer.style.display = "none"
                        cancelContainer.style.display = "block"
                    }
                }).catch((err) => {
                    modalAlert(errorMessage)
                })
            })
        }

        if (Boolean(row.cancelled)) {
            if (!Boolean(row.extendable)) {
                cantRenewContainer.style.display = "block"
            }
            cancelContainer.style.display = "none"
        } else {
            cantRenewContainer.style.display = "none"
            cancelContainer.style.display = "block"
        }
        return container
    }

    protected createSocialMediaTable(data: ISocialMedia[]): HTMLElement {
        const table = document.createElement("table")
        for (const row of data) {
            const tr = document.createElement("tr")
            const name = this.createTableData(true)
            const price = this.createTableData()
            const duration = this.createTableData()

            name.appendChild(this.createUsernameLink(row.social_media.owner))
            name.appendChild(document.createTextNode(`'s ${row.social_media.title_name}:`))
            if (!row.social_media.is_fanclub_type) {
                price.innerText = `${row.social_media.token_price} ${i18n.tokenOrTokensText(row.social_media.token_price, false)}`
            }

            if (row.expires) {
                duration.innerText = `${i18n.expires}: ${row.expiration_string}`
                const extendTextContainer = document.createElement("div")
                if (row.social_media.extendable) {
                    const extendLink = document.createElement("a")
                    addColorClass(extendLink, colorClass.hrefColor)
                    extendLink.style.display = "block"
                    extendLink.innerText = row.extend_label
                    extendLink.href = normalizeResource(row.extend_url)
                    extendLink.onclick = (event: Event) => {
                        event.preventDefault()
                        safeWindowOpen(extendLink.href, "_blank", mediumPopupLinkFeatures)
                    }
                    extendTextContainer.appendChild(extendLink)
                } else {
                    addColorClass(extendTextContainer, "membershipLightText")
                    extendTextContainer.innerText = `(${i18n.cantExtend})`
                }
                duration.appendChild(extendTextContainer)
            } else {
                const expirationStringContainer = document.createElement("div")
                addColorClass(expirationStringContainer, "membershipLightText")
                expirationStringContainer.innerText = row.expiration_string
                duration.appendChild(expirationStringContainer)
            }
            table.appendChild(tr)
            tr.appendChild(name)
            tr.appendChild(price)
            tr.appendChild(duration)
        }
        return table
    }

    protected createExpiredSocialMediaTable(data: ISocialMedia[]): HTMLElement {
        const table = document.createElement("table")
        for (const row of data) {
            const tr = document.createElement("tr")
            const name = this.createTableData(true)
            const price = this.createTableData()
            const duration = this.createTableData()

            name.appendChild(this.createUsernameLink(row.social_media.owner))
            name.appendChild(document.createTextNode(`'s ${row.social_media.title_name}:`))

            if (!row.social_media.is_fanclub_type) {
                price.innerText = `${row.social_media.token_price} ${i18n.tokenOrTokensText(row.social_media.token_price, false)}`
            }

            const extendTextContainer = document.createElement("div")
            if (row.social_media.extendable) {
                const extendLink = document.createElement("a")
                addColorClass(extendLink, colorClass.hrefColor)
                extendLink.style.display = "block"
                extendLink.innerText = row.extend_label
                extendLink.href = normalizeResource(row.extend_url)
                extendLink.onclick = (event: Event) => {
                    event.preventDefault()
                    safeWindowOpen(extendLink.href, "_blank", mediumPopupLinkFeatures)
                }
                extendTextContainer.appendChild(extendLink)
            } else {
                addColorClass(extendTextContainer, "membershipLightText")
                extendTextContainer.innerText = `(${i18n.cantRenew})`
            }
            duration.appendChild(extendTextContainer)

            table.appendChild(tr)
            tr.appendChild(name)
            tr.appendChild(price)
            tr.appendChild(duration)
        }
        return table
    }

    protected createExpiredFanClubTable(data: IMembership[]): HTMLElement {
        const table = document.createElement("table")
        for (const row of data) {
            const tr = document.createElement("tr")
            const name = this.createTableData(true)
            const empty = this.createTableData()
            const duration = this.createTableData()

            if (row.type === "fanclub") {
                name.appendChild(this.createUsernameLink(row.username))
            }
            name.appendChild(document.createTextNode(`${row.name}:`))

            if (row.expired_at !== undefined) {
                duration.appendChild(document.createTextNode(`${i18n.expired}: ${new Date(row.expired_at).toLocaleDateString()}`))
            }
            const extendTextContainer = document.createElement("div")
            if (Boolean(row.extendable)) {
                const extendLink = document.createElement("a")
                addColorClass(extendLink, colorClass.hrefColor)
                extendLink.href = normalizeResource(`/fanclub/join/${row.username}/`)
                extendLink.innerText = i18n.renew
                extendLink.style.display = "block"
                extendLink.target = "_blank"
                extendTextContainer.appendChild(extendLink)
            } else {
                addColorClass(extendTextContainer, "membershipLightText")
                extendTextContainer.innerText = `(${i18n.cantRenew})`
            }
            duration.appendChild(extendTextContainer)

            table.appendChild(tr)
            tr.appendChild(name)
            tr.appendChild(empty)
            tr.appendChild(duration)
        }
        return table
    }

    private createTableData(isFirst = false): HTMLElement {
        const td = document.createElement("td")
        addColorClass(td, "contentText")
        if (isFirst) {
            td.style.width = "330px"
        } else {
            td.style.width = "150px"
        }
        td.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        td.style.paddingTop = "4px"
        return td
    }

    protected createUsernameLink(username = ""): HTMLAnchorElement {
        const usernameLink = document.createElement("a")
        addColorClass(usernameLink, "username")
        usernameLink.innerText = usernameTitleCase(username)
        usernameLink.href = normalizeResource(`/${username}/`)
        usernameLink.style.font = "12px 'UbuntuBold', Arial, Helvetica, sans-serif"
        usernameLink.style.display = "inline-block"

        return usernameLink
    }
}
