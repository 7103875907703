import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass } from "../../../cb/colorClasses"
import { getCb } from "../../api"
import { i18n } from "../../translation"
import { dom } from "../../tsxrender/dom"
import { BaseRoomTab } from "./baseRoomTab"

interface IBroadcastStats {
    broadcastStart?: string,
    broadcastStartTime?: string,
    broadcastDayOfWeek?: string,
    timeOnlineNice?: string,
    broadcastLengthSeconds?: number,
    maxUsers?: number,
    tokensTotal?: number,
    avgTokensPerMin?: number,
    avgTokensPerUser?: number,
    messagesTotal?: number,
    avgMessagesPerUser?: number,
}

interface IAggregateStats {
    avgMaxUsersPerStream?: number | string,
    avgTokensPerStream?: number | string,
    avgMessagesPerStream?: number | string,
}

export class BroadcasterStatsTab extends BaseRoomTab<IBroadcastStats> {
    private tableHeaders = [i18n.broadcastDate, i18n.broadcastWeekday, i18n.broadcastLength, i18n.maxViewers, i18n.tokensTotal, i18n.avgTokensPerMin, i18n.avgTokensPerUser, i18n.chatMessagesTotal, i18n.avgMessagesPerUser]
    private isLoading = false
    private broadcasterStreamData: IBroadcastStats[] = []
    private aggregateStreamData: IAggregateStats[] = []
    private daysBack = 7
    private statsStartDate = this.calcStartAndEndDate(this.daysBack).start
    private statsEndDate = this.calcStartAndEndDate(this.daysBack).end
    private sortBy = "default"
    private sortOrder = "default"

    constructor() {
        super()
        this.calcStartAndEndDate(this.daysBack)
        this.getBroadcasterStats()
    }

    public getBroadcasterStats(): void {
        if (this.isLoading) {
            return
        }
        this.isLoading = true
        super.clearContent()
        getCb(`api/ts/accounts/broadcasters_stats/?start_datetime=${this.statsStartDate}%2000:00:00&end_datetime=${this.statsEndDate}%2000:00:00`).then((response) => {
            const data = new ArgJSONMap(response.responseText)
            data["parsed"]["aggregate_stats"]["avg_max_users_per_stream"] ??= 0
            data["parsed"]["aggregate_stats"]["avg_tokens_per_stream"] ??= 0
            data["parsed"]["aggregate_stats"]["avg_messages_per_stream"] ??=0
            this.parseAggregateData(data["parsed"]["aggregate_stats"])
            this.parseStreamData(data["parsed"])
            this.createContent()
        }).catch((err) => {
            debug("error getting broadcaster stats", err)
        }).finally(() => {
            this.isLoading = false
        })
    }

    protected parseStreamData(rawData: { stream_stats: object[] }): void {
        this.broadcasterStreamData = rawData["stream_stats"].map((data: object) => this.parseData(data))
    }

    protected createContent(): void {
        if (this.sortBy !== "default" && this.sortOrder !== "default") {
            this.sortTableData(this.sortBy, this.sortOrder)
        }
        const content = 
        <div>
            <h1 style={{ display: "inline-block" }}>Broadcaster Stats Dashboard</h1>
            <StatTabDateSelect
                aggregateData={this.aggregateStreamData[0]}
                daysBack={this.daysBack}
                handleDateSelection={this.handleDateSelection}
            />
            <StatTabSortSelect
                aggregateData={this.aggregateStreamData[0]}
                sortBy={this.sortBy}
                sortOrder={this.sortOrder}
                handleSortSelection={this.handleSortSelection}
            />
            <StatsTableSummary
                aggregateData={this.aggregateStreamData[0]}
            />
            <StatsTable headers={this.tableHeaders} data={this.broadcasterStreamData}/>
        </div>
        
        addColorClass(this.element, "TokenStatsTab")
        this.element.appendChild(content)
    }

    protected parseData(rawData: string|object): IBroadcastStats {
        const dataMap = new ArgJSONMap(rawData)
        const parsedData: IBroadcastStats = {}
        parsedData.broadcastStart = dataMap.getString("broadcast_start_time")
        parsedData.broadcastStartTime = new Date(dataMap.getString("broadcast_start_time")).toLocaleTimeString(undefined, { hour: "numeric", minute: "numeric", hour12: true, timeZoneName: "short" })
        parsedData.broadcastDayOfWeek = new Date(dataMap.getString("broadcast_start_time")).toLocaleDateString(undefined, { weekday: "long" })
        parsedData.timeOnlineNice = dataMap.getString("time_online_nice")
        parsedData.broadcastLengthSeconds = Math.round((dataMap.getNumber("broadcast_length_in_secs") + Number.EPSILON) * 100) / 100
        parsedData.maxUsers = Math.round((dataMap.getNumber("max_users_in_stream") + Number.EPSILON) * 100) / 100
        parsedData.tokensTotal = Math.round((dataMap.getNumber("tokens_total_in_stream") + Number.EPSILON) * 100) / 100
        parsedData.avgTokensPerMin = Math.round((dataMap.getNumber("avg_tokens_per_minute") + Number.EPSILON) * 100) / 100
        parsedData.avgTokensPerUser = Math.round((dataMap.getNumber("avg_tokens_per_join") + Number.EPSILON) * 100) / 100
        parsedData.messagesTotal = Math.round((dataMap.getNumber("chat_messages_total_in_stream") + Number.EPSILON) * 100) / 100
        parsedData.avgMessagesPerUser = Math.round((dataMap.getNumber("avg_messages_per_join") + Number.EPSILON) * 100) / 100
        dataMap.logUnusedDebugging("BroadcastStatsTabStreamData")
        return parsedData
    }

    protected parseAggregateData(rawData: object): void {
        const dataMap = new ArgJSONMap(rawData)
        const parsedData = {
            avgMaxUsersPerStream: isNaN(Math.round((dataMap.getNumber("avg_max_users_per_stream") + Number.EPSILON) * 100) / 100) || Math.round((dataMap.getNumber("avg_max_users_per_stream") + Number.EPSILON) * 100) / 100 === 0 ? "-" : Math.round((dataMap.getNumber("avg_max_users_per_stream") + Number.EPSILON) * 100) / 100,
            avgTokensPerStream: isNaN(Math.round((dataMap.getNumber("avg_tokens_per_stream") + Number.EPSILON) * 100) / 100) || Math.round((dataMap.getNumber("avg_tokens_per_stream") + Number.EPSILON) * 100) / 100 === 0 ? "-" : Math.round((dataMap.getNumber("avg_tokens_per_stream") + Number.EPSILON) * 100) / 100,
            avgMessagesPerStream: isNaN(Math.round((dataMap.getNumber("avg_messages_per_stream") + Number.EPSILON) * 100) / 100) || Math.round((dataMap.getNumber("avg_messages_per_stream") + Number.EPSILON) * 100) / 100 === 0 ? "-" : Math.round((dataMap.getNumber("avg_messages_per_stream") + Number.EPSILON) * 100) / 100,
        }
        dataMap.logUnusedDebugging("BroadcastStatsTabAggregateData")
        this.aggregateStreamData = []
        this.aggregateStreamData.push(parsedData)
    }

    protected sortTableData(sortBy: string, sortOrder: string): void {
        if (sortBy === "tokensTotal") {
            this.broadcasterStreamData.sort((a,b) => {
                a.tokensTotal ??= 0
                b.tokensTotal ??= 0
                return sortOrder === "asc" ? a.tokensTotal - b.tokensTotal : b.tokensTotal - a.tokensTotal
            })
        } else if (sortBy === "maxUsers") {
            this.broadcasterStreamData.sort((a,b) => {
                a.maxUsers ??= 0
                b.maxUsers ??= 0
                return sortOrder === "asc" ? a.maxUsers - b.maxUsers : b.maxUsers - a.maxUsers
            })
        } else if (sortBy === "messagesTotal") {
            this.broadcasterStreamData.sort((a,b) => {
                a.messagesTotal ??= 0
                b.messagesTotal ??= 0
                return sortOrder === "asc" ? a.messagesTotal - b.messagesTotal : b.messagesTotal - a.messagesTotal
            })
        }
    }

    private calcStartAndEndDate(daysBack: number): { start: string, end: string } {
        const endDate = new Date()
        endDate.setDate(endDate.getDate() + 1)
        const startDate = new Date()
        startDate.setDate(startDate.getDate() - daysBack)
        const concatStartDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const concatEndDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        return { start: concatStartDate, end: concatEndDate }
    }

    private handleDateSelection = (e: Event): void => {
        const value = parseInt((e.target as HTMLSelectElement).value)
        this.daysBack = value
        this.statsStartDate = this.calcStartAndEndDate(value).start
        this.statsEndDate = this.calcStartAndEndDate(value).end
        this.sortBy = "default"
        this.sortOrder = "default"
        this.getBroadcasterStats()
    }

    private handleSortSelection = (e: Event): void => {
        const value = (e.target as HTMLSelectElement).value
        const sortOptions: Record<string, string> = {}
        sortOptions["default"] = "default"
        sortOptions["usersDesc"] = "maxUsers"
        sortOptions["tokensDesc"] = "tokensTotal"
        sortOptions["messagesDesc"] = "messagesTotal"
        this.sortBy = sortOptions[value]
        this.sortOrder = sortOptions[value] === "default" ? "default" : "desc"
        if (this.sortOrder === "default"){
            this.getBroadcasterStats()
        } else {
            super.clearContent()
            this.createContent()
        }
    }
}

const StatsTable = (props: { headers: string[], data: IBroadcastStats[] }) => {
    const tableStyle: CSSX.Properties = {
        width: "1000px",
        padding: "0px",
        margin: "0px 0px 30px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderCollapse: "collapse",
        borderColor: "#E0E0E0",
    }

    return (
        <div>
            <div style={{ fontSize: "12px", margin: "30px 0px 5px 0px" }}>Average messages and tokens are calculated per room join</div>
            <table className="tokenStatsTable" style={tableStyle}>
                <tr>
                    {props.headers.map((name) => {
                        return <th className="rowHeader" style={{ fontWeight: "normal" }}>{name}</th>
                    })}
                </tr>
                {props.data.map((obj, index) => {
                    return index % 2 === 0 ? <StatsRows data={obj} evenOrOdd="rowEven" /> : <StatsRows data={obj} evenOrOdd="rowOdd" />
                })}
            </table>
        </div>
    )
}

const StatsTableSummary = (props: { aggregateData: IAggregateStats }) => {
    const listStyle: CSSX.Properties = {
        listStyle: "none",
        margin: "60px 0 0 0",
        paddingLeft: "0px",
    }
    const listItemStyle: CSSX.Properties = {
        display: "inline-block",
        marginRight: "60px",
    }

    return (
        <div>
            <div>
                <ul style={listStyle}>
                    <li style={listItemStyle}>Average Max Viewers Per Stream: {props.aggregateData.avgMaxUsersPerStream}</li>
                    <li style={listItemStyle}>Average Tokens Per Stream: {props.aggregateData.avgTokensPerStream}</li>
                    <li style={listItemStyle}>Average Chat Messages Per Stream: {props.aggregateData.avgMessagesPerStream}</li>
                </ul>
            </div>
        </div>
    )
}

const StatTabDateSelect = (props: { aggregateData: IAggregateStats, daysBack: number, handleDateSelection: EventHandler }) => {
    const dateSelectStyle: CSSX.Properties = {
        display: "inline-block",
        marginLeft: "calc(100% - 558px)",
        width: "125px",
    }

    return (
        <span>
            <select style={dateSelectStyle} onChange={props.handleDateSelection} className="fieldInput">
                {props.daysBack === 7 ? <option selected value={7} label="Last 7 Days"></option> : <option value={7} label="Last 7 Days"></option>}
                {props.daysBack === 30 ? <option selected value={30} label="Last 30 Days"></option> : <option value={30} label="Last 30 Days"></option>}
                {/* {props.daysBack === 90 ? <option selected value={90} label={"Last 90 Days"}></option> : <option value={90} label={"Last 90 Days"}></option>} */}
            </select>
        </span>
    )
}

const StatTabSortSelect = (props: { aggregateData: IAggregateStats, sortBy: string, sortOrder: string, handleSortSelection: EventHandler }) => {
    const sortSelectStyle: CSSX.Properties = {
        display: "inline-block",
        marginLeft: "10px",
        width: "225px",
    }

    return (
        <span>
            <select style={sortSelectStyle} onChange={props.handleSortSelection} className="fieldInput">
                {props.sortBy === "default" && props.sortOrder === "default" ? <option selected value="default" label="Sort By..."></option> : <option value="default" label="Sort By..."></option>}
                {props.sortBy === "maxUsers" && props.sortOrder === "desc" ? <option selected value="usersDesc" label="Max Viewers- High to Low"></option> : <option value="usersDesc" label="Users - High to Low"></option>}
                {props.sortBy === "tokensTotal" && props.sortOrder === "desc" ? <option selected value="tokensDesc" label="Total Tokens- High to Low"></option> : <option value="tokensDesc" label="Tokens - High to Low"></option>}
                {props.sortBy === "messagesTotal" && props.sortOrder === "desc" ? <option selected value="messagesDesc" label="Total Messages- High to Low"></option> : <option value="messagesDesc" label="Messages - High to Low"></option>}
            </select>
        </span>
    )
}

const StatsRows = (props: { data: IBroadcastStats, evenOrOdd: string }) => {
    const rowStyle: CSSX.Properties = {
        width: "1000px",
        padding: "0px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderCollapse: "collapse",
        overflowX: "scroll",
        textAlign: "center",
    }
    const startDate = props.data.broadcastStart?.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/i) || ["0000-00-00"]

    return (
        <tr style={rowStyle} className={props.evenOrOdd}>
            <td>{`${new Date(`${startDate[0]}T00:00:00`).toLocaleDateString(undefined, { year: "numeric", month: "short", day: "numeric" })}, ${props.data.broadcastStartTime}`}</td>
            <td>{props.data.broadcastDayOfWeek}</td>
            <td>{props.data.timeOnlineNice}</td>
            <td>{props.data.maxUsers}</td>
            <td>{props.data.tokensTotal}</td>
            <td>{props.data.avgTokensPerMin}</td>
            <td>{props.data.avgTokensPerUser}</td>
            <td>{props.data.messagesTotal}</td>
            <td>{props.data.avgMessagesPerUser}</td>
        </tr>
    )
}
