import { addColorClass } from "../../cb/colorClasses"
import { BroadcastRoomTabs } from "../broadcastlib/broadcastTabs/broadcastRoomTabs"
import { Component } from "../defui/component"
import { i18n } from "../translation"
import type { IProfileContext } from "../../entrypoints/profile"
import type { DismissibleMessages } from "../theatermodelib/dismissibleMessages"

export class ProfileContents extends Component {
    public topSectionWrapper: HTMLDivElement
    readonly roomContentsPadding = 4
    public surveyDiv: HTMLDivElement
    public modeToggleDiv: HTMLDivElement
    public dismissibleMessages: DismissibleMessages
    public offlineRoomNotice: HTMLDivElement

    constructor(context: IProfileContext) {
        super()
        addColorClass(this.element, "BaseRoomContents")
        this.element.style.position = "static"
        this.element.style.display = "block"
        this.element.style.height = "auto"
        this.element.style.borderRadius = "4px"
        this.element.style.padding = `${this.roomContentsPadding}px`
        this.element.style.margin = "12px 0 17px 12px"
        this.element.style.overflow = "visible"
        this.element.style.boxSizing = "border-box"
        this.element.style.width = "calc(100% - 22px)"

        this.offlineRoomNotice = document.createElement("div")
        addColorClass(this.offlineRoomNotice, "offlineRoomNotice")
        this.offlineRoomNotice.innerText = i18n.roomOfflineMessage
        this.offlineRoomNotice.style.display = "none"
        this.offlineRoomNotice.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        this.offlineRoomNotice.style.fontSize = "12px"
        this.offlineRoomNotice.style.fontWeight = "bold"
        this.offlineRoomNotice.style.margin = "13px 0"
        this.element.appendChild(this.offlineRoomNotice)

        this.topSectionWrapper = document.createElement("div")
        addColorClass(this.topSectionWrapper, "topSectionWrapper")
        this.topSectionWrapper.style.width = "100%"
        this.topSectionWrapper.style.height = "auto"
        this.element.appendChild(this.topSectionWrapper)

        const tabs = new BroadcastRoomTabs(context.roomName, context, context.dossier)
        this.addChild(tabs)
    }
}
