import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"
import { getCb } from "../api"
import { Component } from "../defui/component"
import { createDivotTop } from "../divot"
import { i18n } from "../translation"
import type { IInitialBroadcasterDossier } from "../../entrypoints/broadcast"
import type { IProfileContext } from "../../entrypoints/profile"

interface IVote {
    id: number,
    vote: number,
    comment: string,
    datetime: string,
}

interface IRatingHistoryResponse {rating_votes: IVote[]}

export class SatisfactionRating extends Component<HTMLUListElement> {
    private isLoading = false
    private lastLoadedVote = 0
    private hasLoadedData = false
    private ratingHistory: HTMLElement
    private ratingHistoryTable: HTMLElement

    constructor(private context: IProfileContext | IInitialBroadcasterDossier) {
        super("ul")
        addColorClass(this.element, "gradient")
        addColorClass(this.element, "SatisfactionRating")
        this.element.style.width = ""
        this.element.style.height = ""
        this.element.style.listStyle = "none"
        this.element.style.position = "relative"
        this.element.style.margin = "0"
        this.element.style.padding = "0"
        this.element.style.overflow = "visible"
        this.element.style.cssFloat = "right"
        this.element.style.top = "-4px"
        this.element.style.borderRadius = "4px"

        const satisfaction = document.createElement("li")
        satisfaction.style.cssFloat = "right"
        satisfaction.style.margin = "0"
        satisfaction.style.listStyle = "none"
        this.element.appendChild(satisfaction)

        const broadcasterRating = document.createElement("div")
        const ratingTooltip = document.createElement("div")
        const ratingHistoryTriangle = document.createElement("div")
        this.ratingHistory = document.createElement("div")
        this.ratingHistoryTable = document.createElement("div")

        satisfaction.appendChild(broadcasterRating)
        satisfaction.appendChild(ratingTooltip)
        satisfaction.appendChild(ratingHistoryTriangle)
        satisfaction.appendChild(this.ratingHistory)
        this.ratingHistory.appendChild(this.ratingHistoryTable)

        broadcasterRating.style.marginRight = "4px"
        broadcasterRating.style.display = "inline-block"
        broadcasterRating.style.cursor = "default"
        ratingTooltip.style.display = "none"
        ratingHistoryTriangle.style.display = "none"
        ratingHistoryTriangle.style.position = "absolute"
        ratingHistoryTriangle.style.zIndex = "1000"
        ratingHistoryTriangle.style.marginTop = "6px"
        ratingHistoryTriangle.style.marginLeft = "65px"
        ratingHistoryTriangle.style.width = "0"
        ratingHistoryTriangle.style.height = "0"
        ratingHistoryTriangle.style.borderLeft = "5px solid transparent"
        ratingHistoryTriangle.style.borderRight = "5px solid transparent"
        ratingHistoryTriangle.style.borderBottom = "5px solid #0b5d81"

        this.ratingHistory.style.display = "none"
        this.ratingHistoryTable.style.display = "table"
        this.ratingHistoryTable.style.width = "100%"

        addColorClass(ratingTooltip, "tooltip")
        addColorClass(ratingTooltip, colorClass.defaultTooltipColor)
        ratingTooltip.style.position = "absolute"
        ratingTooltip.style.zIndex = "1000"
        ratingTooltip.style.marginTop = "11px"
        ratingTooltip.style.marginLeft = "-120px"
        ratingTooltip.style.width = "225px"
        ratingTooltip.style.borderWidth = "2px"
        ratingTooltip.style.borderStyle = "solid"
        ratingTooltip.style.padding = "0.5em 0.8em 0.8em 2em"
        ratingTooltip.style.borderRadius = "6px"
        ratingTooltip.style.fontSize = "14px"

        addColorClass(this.ratingHistory, "ratingHistory")
        this.ratingHistory.style.borderWidth = "3px"
        this.ratingHistory.style.borderStyle = "solid"
        this.ratingHistory.style.width = "300px"
        this.ratingHistory.style.height = "auto"
        this.ratingHistory.style.maxHeight = "300px"
        this.ratingHistory.style.overflowX = "hidden"
        this.ratingHistory.style.overflowY = "auto"
        this.ratingHistory.style.borderRadius = "5px"
        this.ratingHistory.style.padding = "2px"
        this.ratingHistory.style.position = "absolute"
        this.ratingHistory.style.zIndex = "1000"
        this.ratingHistory.style.marginTop = "11px"
        this.ratingHistory.style.marginLeft = "-165px"

        const btnLeft = this.createSatisfactionSpan()
        const btnMiddle = this.createSatisfactionSpan()
        const btnRight = this.createSatisfactionSpan()

        broadcasterRating.appendChild(btnLeft)
        broadcasterRating.appendChild(btnMiddle)
        broadcasterRating.appendChild(btnRight)
        btnLeft.style.position = "relative"
        btnLeft.style.left = "3px"
        btnRight.style.position = "relative"
        btnRight.style.left = "-3px"
        btnLeft.appendChild(this.createThumbPlaceholder())
        btnRight.appendChild(this.createThumbPlaceholder())

        const thumbUpText = document.createElement("span")
        const thumbUpImg = createThumbUp(false)
        const ratingPercentage = document.createElement("span")
        const thumbDownText = document.createElement("span")
        const thumbDownImg = createThumbDown(false)

        btnMiddle.appendChild(thumbUpText)
        btnMiddle.appendChild(thumbUpImg)
        btnMiddle.appendChild(ratingPercentage)
        btnMiddle.appendChild(thumbDownImg)
        btnMiddle.appendChild(thumbDownText)

        addColorClass(thumbUpText, "voteText")
        thumbUpText.style.position = "relative"
        thumbUpText.style.top = "-2px"
        thumbUpText.style.marginRight = "8px"
        thumbUpText.style.paddingLeft = "4px"

        ratingPercentage.style.position = "relative"
        ratingPercentage.style.top = "-2px"
        ratingPercentage.style.left = "-8px"
        if (this.context.votePercent <= 65) {
            addColorClass(ratingPercentage, "lowPercent")
        } else if (this.context.votePercent <= 85) {
            addColorClass(ratingPercentage, "mediumPercent")
        } else {
            addColorClass(ratingPercentage, "highPercent")
        }

        addColorClass(thumbDownText, "voteText")
        thumbDownText.style.position = "relative"
        thumbDownText.style.top = "-2px"
        thumbDownText.style.marginLeft = "8px"
        thumbDownText.style.paddingRight = "4px"

        thumbUpText.innerText = `${this.context.upVotes}`
        ratingPercentage.innerText = `${this.context.votePercent}%`
        thumbDownText.innerText = `${this.context.downVotes}`

        const ratingTooltipDivot = createDivotTop("", colorClass.defaultTooltipColor, "150px", 3)
        addColorClass(ratingTooltipDivot, "divotTop")

        const line1 = document.createElement("span")
        const line2 = document.createElement("span")
        const line3 = document.createElement("span")
        line1.innerText = i18n.thisIsSatisfactionScore
        line2.innerText = i18n.dataUpdatedOncePerDay
        line3.innerText = i18n.clickScoreToSeeHistory
        ratingTooltip.appendChild(ratingTooltipDivot)
        ratingTooltip.appendChild(line1)
        ratingTooltip.appendChild(document.createElement("br"))
        ratingTooltip.appendChild(document.createElement("br"))
        ratingTooltip.appendChild(line2)
        ratingTooltip.appendChild(document.createElement("br"))
        ratingTooltip.appendChild(document.createElement("br"))
        ratingTooltip.appendChild(line3)

        let historyShowing = false
        satisfaction.onmouseover = () => {
            if (!historyShowing) {
                ratingTooltip.style.display = "block"
            }
        }
        satisfaction.onmouseleave = () => {
            ratingTooltip.style.display = "none"
        }

        const hideHistory = () => {
            this.ratingHistory.style.display = "none"
            ratingHistoryTriangle.style.display = "none"
            historyShowing = false
            removeEventListenerPoly("click", document.body, hideHistory)
        }
        let isFirstOpen = true
        satisfaction.onclick = (event: Event) => {
            event.preventDefault()
            event.stopPropagation()
            if (historyShowing) {
                hideHistory()
            } else {
                historyShowing = true
                this.ratingHistory.style.display = ""
                ratingHistoryTriangle.style.display = ""
                ratingTooltip.style.display = "none"
                addEventListenerPoly("click", document.body, hideHistory)
                if (isFirstOpen) {
                    isFirstOpen = false
                    this.ratingHistoryTable.innerText = `${i18n.fetchingHistory}...`
                    this.fetchRatingHistory()
                }
            }
        }
        this.ratingHistory.onclick = (event: Event) => {
            event.stopPropagation()
        }
        this.ratingHistory.onscroll = () => {
            if (this.isLoading) {
                return
            }
            if (this.ratingHistory.scrollHeight + this.ratingHistory.scrollTop >= this.ratingHistory.offsetHeight) {
                this.fetchRatingHistory(this.lastLoadedVote)
            }
        }
    }

    private fetchRatingHistory(skip?: number): void {
        this.isLoading = true
        getCb(`api/ts/tipping/rating-history/?skip=${skip === undefined ? "" : skip}`).then((response) => {
            const data: IRatingHistoryResponse = parseRatingHistory(response.responseText)
            if (data.rating_votes.length === 0) {
                if (!this.hasLoadedData) {
                    this.ratingHistoryTable.innerText = i18n.noHistoryToShow
                }
                return
            }
            if (!this.hasLoadedData) {
                this.ratingHistoryTable.innerText = ""
            }
            this.isLoading = false
            this.hasLoadedData = true
            for (const vote of data.rating_votes) {
                this.ratingHistoryTable.appendChild(new Vote(vote).element)
                this.lastLoadedVote = vote.id
            }
        }).catch(() => {})
    }

    private createSatisfactionSpan(): HTMLSpanElement {
        const span = document.createElement("span")
        addColorClass(span, "satisfactionSpan")
        span.style.display = "inline-block"
        span.style.height = "21px"
        span.style.minWidth = "8px"
        span.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        span.style.fontSize = "14px"
        span.style.paddingTop = "2px"

        return span
    }

    private createThumbPlaceholder(): HTMLSpanElement {
        const placeholder = document.createElement("span")
        placeholder.style.position = "relative"
        placeholder.style.top = "1px"
        placeholder.style.display = "inline-block"
        placeholder.style.height = "16px"
        placeholder.style.width  = "1px"

        return placeholder
    }
}

class Vote extends Component {
    constructor(vote: IVote) {
        super()

        addColorClass(this.element, "vote")
        this.element.style.height = ""
        this.element.style.width = ""
        this.element.style.position = "static"
        this.element.style.display = "block"
        this.element.style.borderWidth = "2px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderRadius = "5px"
        this.element.style.minHeight = "16px"
        this.element.style.margin = "2px"
        this.element.style.padding = "5px"

        const thumbCell = this.createCell()
        let thumbIcon: HTMLSpanElement
        const commentCell = this.createCell()
        const commentText = document.createElement("span")
        commentText.style.marginRight = "10px"
        commentText.style.display = "inline-block"
        commentText.style.wordWrap = "break-word"
        commentText.style.maxWidth = "230px"
        const commentDate = document.createElement("span")
        addColorClass(commentDate, "commentDate")
        commentDate.style.marginRight = "10px"
        commentDate.style.display = "inline-block"
        commentDate.style.whiteSpace = "nowrap"

        if (vote.vote > 0) {
            thumbIcon = createThumbUp(true)
            addColorClass(this.element, "positiveVote")
        } else {
            thumbIcon = createThumbDown(false)
            addColorClass(this.element, "negativeVote")
        }
        thumbIcon.style.marginRight = "16px"
        if (vote.comment.length > 25) {
            commentText.innerText = `${vote.comment.slice(0, 22)}...`
            const showMore = document.createElement("span")
            addColorClass(showMore, colorClass.hrefColor)
            showMore.innerText = "more"
            showMore.style.marginLeft = "5px"
            showMore.style.cursor = "pointer"
            showMore.onclick = () => {
                commentText.innerText = vote.comment
            }
            commentText.appendChild(showMore)
        } else if (vote.comment.length === 0) {
            commentText.style.display = "none"
        } else {
            commentText.innerText = vote.comment
        }
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric", timeZone: "UTC" }
        commentDate.innerText = new Date(vote.datetime).toLocaleDateString(undefined, options)

        this.element.appendChild(thumbCell)
        thumbCell.appendChild(thumbIcon)
        this.element.appendChild(commentCell)
        commentCell.appendChild(commentText)
        commentCell.appendChild(commentDate)
    }

    private createCell(): HTMLElement {
        const cell = document.createElement("div")
        cell.style.display = "table-cell"
        cell.style.verticalAlign = "middle"
        return cell
    }
}

function parseRatingHistory(response: string): IRatingHistoryResponse {
    const responseMap = new ArgJSONMap(response)
    const ratingHistory: IRatingHistoryResponse = { rating_votes: [] }
    const votes = responseMap.getList("rating_votes")
    if (votes !== undefined) {
        const parsedRatings = votes.map(vote => {
            return {
                id: vote.getNumber("id"),
                vote: vote.getNumber("vote"),
                comment: vote.getString("comment"),
                datetime: vote.getString("datetime"),
            }
        })
        ratingHistory.rating_votes = parsedRatings
    }
    responseMap.logUnusedDebugging("parse SatisfactionScore RatingHistory")

    return ratingHistory
}

function createThumbUp(active: boolean): HTMLSpanElement {
    const thumbUpImg = document.createElement("span")
    thumbUpImg.style.position = "relative"
    thumbUpImg.style.top = "1px"
    thumbUpImg.style.background = `url(${STATIC_URL}thumb_sprites.png) no-repeat 0px ${active ? "-16" : "0"}px`
    thumbUpImg.style.display = "inline-block"
    thumbUpImg.style.height = "16px"
    thumbUpImg.style.width = "16px"
    thumbUpImg.style.marginRight = "16px"

    return thumbUpImg
}

function createThumbDown(active: boolean): HTMLSpanElement {
    const thumbDownImg = document.createElement("span")
    thumbDownImg.style.position = "relative"
    thumbDownImg.style.top = "1px"
    thumbDownImg.style.background = `url(${STATIC_URL}thumb_sprites.png) no-repeat -16px ${active ? "-16" : "0"}px`
    thumbDownImg.style.display = "inline-block"
    thumbDownImg.style.height = "16px"
    thumbDownImg.style.width = "16px"

    return thumbDownImg
}
