import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { Component } from "../defui/component"
import { loadRoomRequest } from "../fullvideolib/userActionEvents"
import type { ReactComponent } from "../../cb/components/ReactRegistry"
import type { ITabInstance } from "../roomTabs"

/** React wrapper for contest stats, since we currently don't have any room tabs component in react */
export class ContestStatsReactWrapper extends Component implements ITabInstance {
    private reactComponent: ReactComponent

    constructor() {
        super()
        this.element.style.position = "static"
        const ContestStatsClass = ReactComponentRegistry.get("ContestStats")
        this.reactComponent = new ContestStatsClass({
            "show": false,
            "withAdditionalDetails": true,
            "loadRoomRequestFire": (event: string) => loadRoomRequest.fire(event),
        }, this.element)
    }

    show(): void {
        this.reactComponent.update({ "show": true })
    }

    hide(): void {
        this.reactComponent.update({ "show": false })
    }

    checkBeforeSwitch(switchCallback: () => void): void {
        switchCallback()
    }
}
