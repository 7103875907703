import { t } from "@lingui/macro"
import { isMobileDevice, unloadEventName } from "@multimediallc/web-utils/modernizr"
import { addColorClass, colorClass, removeColorClass } from "../../../cb/colorClasses"
import { currentSiteSettings } from "../../../cb/siteSettings"
import { DjangoForm } from "../../../cb/ui/djangoForm"
import { Input, MultipleSelect, Select, StyledCheckboxInput, TextArea } from "../../../cb/ui/fields"
import { FieldStyles, SettingsTextAreaStyles } from "../../../cb/ui/fieldStyles"
import { addEventListenerPoly } from "../../addEventListenerPolyfill"
import { modalAlert } from "../../alerts"
import { normalizeResource, postCb } from "../../api"
import { Debouncer, DebounceTypes } from "../../debouncer"
import { applyStyles } from "../../DOMutils"
import { EventRouter } from "../../events"
import { featureFlagIsActive } from "../../featureFlag"
import { isLanguageSubdomainActive } from "../../featureFlagUtil"
import { Gender } from "../../genders"
import { addPageAction } from "../../newrelic"
import { isProfilePage } from "../../profilelib/profileRoot"
import { styleTransition } from "../../safeStyle"
import { i18n } from "../../translation"
import { parseQueryString } from "../../urlUtil"
import { broadcastSettingsSave, genderSettingsSave, optInFlagDisplaySettingsSave } from "../userActionEvents"
import type { IDjangoField, IDjangoFormData } from "../../../cb/ui/djangoForm"
import type { Field } from "../../../cb/ui/fields"

export class SettingsTabForm extends DjangoForm {
    public settingsSavedRequest = new EventRouter<boolean>("settingsSavedRequest")

    private browserNotificationHelpText: HTMLElement
    private pageReloadNeeded = false
    private languageChanged = false

    constructor(data: IDjangoFormData, endpoint: string) {
        super(data, endpoint, {
            onSubmitSuccess: (formData: FormData) => {
                broadcastSettingsSave.fire({
                    allowPrivateShows: formData.get("allow_private_shows") === "True",
                    hasRoomPassword: Boolean(formData.get("password_for_cam")),
                })

                genderSettingsSave.fire({
                    gender: String(formData.get("gender")),
                    subgender: String(formData.get("subgender")),
                })

                optInFlagDisplaySettingsSave.fire(
                    formData.get("opt_in_flag_display") === "True",
                )

                const value = formData.get("opt_out_of_sharing_score")
                if (value !== satisfactionScoreOriginalValue) {
                    let enabled: string
                    if (value === "True") {
                        enabled = "false"
                        satisfactionScoreOriginalValue = "True"
                    } else {
                        enabled = "true"
                        satisfactionScoreOriginalValue = "False"
                    }
                    addPageAction("SatisfactionScoreSettingsClicked", { "Enabled": enabled })
                }

                const languageCode = formData.get("language") as string
                this.settingsSavedRequest.fire(true)
                // Don't trigger page reload if there was a form change while the current successful API is still resolving
                // To avoid setting stale language if it was changed in quick succession.
                if (this.pageReloadNeeded && !this.formHasChanged()) {
                    if (isLanguageSubdomainActive() && !currentSiteSettings.isWhiteLabel && this.languageChanged) {
                        window.location.href = getURLWithLanguageSubdomain(languageCode)
                    } else {
                        window.location.reload()
                    }
                }
            },
            onSubmitError: () => {
                this.settingsSavedRequest.fire(false)
            },
            styles: {
                field: new SettingsTabFieldStyles(),
                input: new SettingsTabFieldStyles(),
                select: new SettingsTabSelectFieldStyles(),
                checkbox: new SettingsTabCheckboxFieldStyles(),
                multipleSelect: new SettingsTabCheckboxFieldStyles(),
                multipleCheckboxes: new SettingsTabMultipleSelectFieldStyles(),
            },
        }, true)
        this.element.style.paddingBottom = "14px"
        const browserNotificationField = this.getField("toggle_browser_notifications") as StyledCheckboxInput
        this.initializeBrowserNotificationCheckbox(browserNotificationField)

        const genderField = this.getField("gender")
        const subgenderField = this.getField("subgender")

        const makeSubgenderVisibileOnTrans = (genderInput: HTMLSelectElement) => {
            if (subgenderField !== undefined) {
                const subgenderInput = subgenderField.getWidget() as HTMLSelectElement
                if (genderInput.value === Gender.OldTrans || genderInput.value === Gender.Trans) {
                    subgenderInput.style.display = "inline"
                    subgenderInput.focus()
                    genderField?.clearError()
                } else {
                    subgenderInput.style.display = "none"
                    subgenderInput.value = ""
                    subgenderField?.clearError()
                }
            }
        }

        this.placeSubgenderField()

        if (genderField !== undefined) {
            const genderInput = genderField.getWidget() as HTMLSelectElement
            makeSubgenderVisibileOnTrans(genderInput)
            genderField.setOnChange(() => {
                if (genderField.getValue() === Gender.Couple) {
                    genderField.updateLabelText(i18n.sexTextCouple)
                } else {
                    genderField.updateLabelText(i18n.sexText)
                }
                makeSubgenderVisibileOnTrans(genderInput)
            })
        }

        this.getField("language")?.setOnChange(() => {
            if (isLanguageSubdomainActive() && !currentSiteSettings.isWhiteLabel) {
                this.pageReloadNeeded = true
                this.languageChanged = true
            } else {
                this.pageReloadNeeded = true
            }
        })
        if (!isProfilePage()) {
            this.removeField("language")
        }
        if (featureFlagIsActive("PremPrivShow")) {
            this.setupPremiumSection()
        }
        if (featureFlagIsActive("FanClubSpying")) {
            this.setupFanClubSpying()
        }
        let satisfactionScoreOriginalValue = this.getField("opt_out_of_sharing_score")?.getValue()
        this.setTestIds()
    }

    protected handleAutoSaveAndUnsavedLabels(): void {
        // Using a debouncer to ensure that if a user changes multiple fields in short span of time,
        // all the changes should get saved once after inactivity of 1 sec
        // rather than multiple api calls with every single change.
        const onFieldChangedDebouncer = new Debouncer(() => {
            this.triggerExternalSubmit()
        }, { debounceType: DebounceTypes.debounce, bounceLimitMS: 1000 })

        const genderField = this.getField("gender")
        const subgenderField = this.getField("subgender")

        for (const key of Object.keys(this.getFields())) {
            const field = this.getField(key)
            if (field !== undefined) {
                field.fieldChanged.listen(() => {
                    // Special case where subgender field doesn't have a label
                    // so setting gender field's unsaved label instead
                    if (field.getName() === "subgender") {
                        genderField?.setUnsaved(true)
                    }
                    if (field.getName() === "gender" && subgenderField?.hasError() === true) {
                        subgenderField?.clearError()
                    }
                    field.setUnsaved(true)

                    // We want to avoid hitting the api when trans is selected for gender and the subgender is not changed yet
                    // Focus will shift to subgender if trans is selected and the fieldTouched listener will call the debouncer
                    if (!(field.getName() === "gender" &&
                        (genderField?.getValue() === Gender.OldTrans || genderField?.getValue() === Gender.Trans) &&
                        (document.activeElement === genderField.getWidget() || document.activeElement === subgenderField?.getWidget()))) {
                        onFieldChangedDebouncer.callFunc()
                    }
                })
            }
        }

        subgenderField?.fieldTouched.listen(() => {
            if (subgenderField.getValue() === "" && genderField?.unsaved === true) {
                onFieldChangedDebouncer.callFunc()
            }
        })

        // If the user closes the page, we try to call the submit api to save form changes
        addEventListenerPoly(unloadEventName(), window, () => this.triggerExternalSubmit())
    }

    private setTestIds() {
        const testIds: Record<string, string> = {
            "gender": "gender-selection",
            "subgender": "subgender-selection",
            "email": "email",
            "email_when_follow_comes_on": "email-when-follow-comes-on",
            "toggle_browser_notifications": "toggle-browser-notifications",
            "app_dev_subscription": "app-dev-subscription",
            "newsletter_subscription": "newsletter-subscription",
            "show_cam_in_index": "show-cam-in-index",
            "opt_in_flag_display": "opt-in-flag-display",
            "password_for_cam": "password-for-cam",
            "chat_rules": "chat-rules",
            "block_from_countries": "block-from-countries",
            "block_from_states": "block-from-states",
            "opt_out_of_sharing_info": "opt-out-of-sharing-info",
            "opt_out_of_sharing_score": "opt-out-of-sharing-score",
            "allow_private_shows": "allow-private-shows",
            "allow_show_recordings": "allow-show-recordings",
            "private_show_tokens_per_minute": "private-show-tokens-per-minute",
            "private_show_minimum_minutes": "private-show-minimum-minutes",
            "spy_on_private_show_tokens_per_minute": "spy-on-private-show-tokens-per-minute",
        }

        const field = this.getField("show_cam_to_genders")
        if (field !== undefined) {
            const elements = field.getField().querySelectorAll("input")
            for (const element of elements) {
                if (element !== null) {
                    element.dataset.testid = "show-cam-to-genders-checkbox"
                }
            }
        }
        for (const elem in testIds) {
            const field = this.getField(elem)
            if (field !== undefined) {
                field.getWidget().dataset.testid = testIds[elem]
            }
        }
    }

    private placeSubgenderField(): void {
        const genderSelect = this.getField("gender")
        const subgenderSelect = this.getField("subgender")

        if (genderSelect !== undefined && subgenderSelect !== undefined) {
            const genderDataCell = genderSelect.getField().querySelector("td")
            const subgenderDataCell = subgenderSelect.getField().querySelector("td")

            if (genderDataCell !== null && subgenderDataCell !== null) {
                subgenderDataCell.childNodes.forEach(node => genderDataCell.appendChild(node))
            }
        }
    }

    protected createField(field: IDjangoField): Field {
        const shouldHighlightEmail = parseQueryString(window.location.search)["tab"] === "settings_email"
        const blockLocationField = this.overrideBlockLocationField(field)

        if (blockLocationField !== undefined) {
            return blockLocationField
        }
        if (field.name === "toggle_browser_notifications") {
            this.browserNotificationHelpText = document.createElement("div")
            return new StyledCheckboxInput({
                ...field,
                helpText: this.browserNotificationHelpText,
                // @ts-ignore not type checking django forms
                onChange: (field: StyledCheckboxInput) => this.onBrowserNotificationCheckboxChange(this.data.extra["signed_username"], field),
                styles: new SettingsTabNotifictionCheckboxFieldStyles(),
            })
        } else if (field.name === "chat_rules") {
            return new TextArea({
                ...field,
                styles: new SettingsTextAreaStyles(),
            })
        }
        const notificationField = this.overrideNotificationField(field)
        if (notificationField !== undefined) {
            return notificationField
        }
        if (field.fieldType === "EmailField") {
            return new Input({
                ...field,
                defaultValue: field.defaultValue === "" ? i18n.noEmailOnFile : field.defaultValue,
                // @ts-ignore not type checking django forms
                helpText: this.createEmailHelpText(this.data.initial[field.name], this.data.extra["verified_email"]),
                styles: shouldHighlightEmail ? new HighlightedEmailFieldStyles() : new EmailFieldStyles(),
            })
        }
        const pushServiceField = this.overridePushServiceField(field)
        if (pushServiceField !== undefined) {
            return pushServiceField
        }

        const optInFlagDisplayField = this.overrideOptInFlagDisplayField(field)
        if (optInFlagDisplayField !== undefined) {
            return optInFlagDisplayField
        }

        return super.createField(field)
    }

    private overrideBlockLocationField(field: IDjangoField): Field | undefined {
        if (field.name === "block_from_countries") {
            if (isMobileDevice()) {
                return new Select({
                    ...field,
                    multiple: true,
                    styles: new SettingsTabSelectFieldStyles(),
                })
            } else {
                return new MultipleSelect({
                    ...field,
                    selectedHeader: i18n.blockedCountries,
                    unselectedHeader: i18n.ableToView,
                    styles: new SettingsTabMultipleSelectFieldStyles(),
                })
            }
        } else if (field.name === "block_from_states") {
            if (isMobileDevice()) {
                return new Select({
                    ...field,
                    multiple: true,
                    styles: new SettingsTabSelectFieldStyles(),
                })
            } else {
                return new MultipleSelect({
                    ...field,
                    selectedHeader: i18n.blockedRegions,
                    unselectedHeader: i18n.ableToView,
                    styles: new SettingsTabMultipleSelectFieldStyles(),
                })
            }
        }
        return undefined
    }

    private overrideNotificationField(field: IDjangoField): Field | undefined {
        if (field.name === "email_when_follow_comes_on") {
            return new StyledCheckboxInput({
                ...field,
                onChange: (field: StyledCheckboxInput) => this.onFollowNotificationCheckboxChange(field),
                styles: new SettingsTabNotifictionCheckboxFieldStyles(),
            })
        } else if (field.name === "app_dev_subscription") {
            return new StyledCheckboxInput({
                ...field,
                onChange: (field: StyledCheckboxInput) => this.onAppDevNotificationCheckboxChange(field),
                styles: new SettingsTabNotifictionCheckboxFieldStyles(),
            })
        } else if (field.name === "newsletter_subscription") {
            return new StyledCheckboxInput({
                ...field,
                onChange: (field: StyledCheckboxInput) => this.onNewsletterNotificationCheckboxChange(field),
                styles: new SettingsTabNotifictionCheckboxFieldStyles(),
            })
        }
        return undefined
    }

    private overridePushServiceField(field: IDjangoField): Field | undefined {
        if (field.name === "events_url") {
            return new Input({
                ...field,
                styles: new AuthFieldStyles(),
            })
        }
        return undefined
    }

    private createEmailHelpText(email = "", isVerified = false): HTMLElement {
        const container = document.createElement("div")
        if (email === "") {
            const addEmailLink = document.createElement("a")
            addColorClass(addEmailLink, colorClass.hrefColor)
            addEmailLink.innerText = i18n.addEmailAddress
            addEmailLink.href = normalizeResource("/accounts/change_email/")
            addEmailLink.target = "_blank"
            addEmailLink.dataset.testid = "add-email-link"
            container.appendChild(addEmailLink)
        } else if (isVerified) {
            const changeEmail = document.createElement("a")
            addColorClass(changeEmail, colorClass.hrefColor)
            changeEmail.innerText = i18n.changeEmailAddress
            changeEmail.href = normalizeResource("/accounts/change_email/")
            changeEmail.target = "_blank"
            changeEmail.dataset.testid = "change-email-link"
            container.appendChild(document.createTextNode(i18n.emailVerified))
            container.appendChild(document.createElement("br"))
            container.appendChild(changeEmail)
        } else {
            const resendEmailLink = document.createElement("a")
            addColorClass(resendEmailLink, colorClass.hrefColor)
            resendEmailLink.innerText = i18n.resendVerificationEmail
            resendEmailLink.href = normalizeResource("/emailvalidation/resend/")
            resendEmailLink.target = "_blank"
            resendEmailLink.dataset.testid = "resend-email-link"
            resendEmailLink.onclick = (event: Event) => {
                event.preventDefault()
                postCb("emailvalidation/resend/", {}).then(() => {
                    container.replaceChild(document.createTextNode(`${i18n.verificationEmailSent}.`), resendEmailLink)
                }).catch(() => {
                    modalAlert(i18n.emailSendErrorMessage)
                })
            }

            const changeEmailLink = document.createElement("a")
            addColorClass(changeEmailLink, colorClass.hrefColor)
            changeEmailLink.innerText = i18n.changeEmailAddress
            changeEmailLink.href = normalizeResource("/accounts/change_email/")
            changeEmailLink.target = "_blank"
            changeEmailLink.dataset.testid = "change-email-link"

            container.appendChild(document.createTextNode(i18n.emailIsUnverifiedForNotificationsAndNewsletter))
            container.appendChild(document.createElement("br"))
            container.appendChild(resendEmailLink)
            container.appendChild(document.createElement("br"))
            container.appendChild(changeEmailLink)
        }
        return container
    }

    private setBrowserNotificationText(text: string): void {
        this.browserNotificationHelpText.innerText = text
    }

    private fadeOutBrowserNotificationText(time: number): void {
        styleTransition(this.browserNotificationHelpText, `opacity ${time}ms`)
        this.browserNotificationHelpText.style.opacity = "0"
        window.setTimeout(() => {
            this.setBrowserNotificationText("")
            this.browserNotificationHelpText.style.opacity = "1"
        }, time)
    }

    /* eslint-disable @typescript-eslint/strict-boolean-expressions */
    private initializeBrowserNotificationCheckbox(checkbox: StyledCheckboxInput): void {
        if (!window["isBrowserNotificationSupported"]()) {
            checkbox.setDisabled(true)
            this.setBrowserNotificationText("Browser Notifications are not currently supported on this browser, check back later for when we add support.")
        } else if (window["isBrowserNotificationDenied"]()) {
            checkbox.setDisabled(true)
            this.setBrowserNotificationText("Your browser has notifications off or blocked for this site. You must allow browser notifications in order to use this feature.")
        } else if (window["isBrowserNotificationSupported"]()) {
            window["isSubscribedToBrowserNotifications"](true, () => {
                checkbox.setChecked(true)
            })
        }
    }


    private onBrowserNotificationCheckboxChange(signedUsername: string, checkbox: StyledCheckboxInput): void {
        checkbox.setDisabled(true)
        const isChecked = checkbox.getChecked()
        if (isChecked) {
            window["subscribeUserToPush"](signedUsername).then(() => {
                this.setBrowserNotificationText("Browser notification setting saved.")
                window.setTimeout(() => {
                    if (window["isWebPushBrowserNotificationSupported"]()) {
                        checkbox.setDisabled(false)
                        this.fadeOutBrowserNotificationText(500)
                    }
                }, 2000)
                addPageAction("PushNotificationSettingsUpdated", { "new_status": true, "old_status": false, "location": "settings-tab" })
            }).catch((response: XMLHttpRequest | undefined) => {
                checkbox.setDisabled(true)
                checkbox.setChecked(false)
                if (response !== undefined && response.status === 400) {
                    window.setTimeout(() => {
                        checkbox.setDisabled(false)
                        this.setBrowserNotificationText("Invalid subscription sent to server, please retry. If this continues, contact support.")
                    }, 2000)
                } else {
                    this.setBrowserNotificationText("Your browser has notifications off or blocked for this site. You must allow browser notifications in order to use this feature.")
                }
            })
        } else {
            void window["unsubscribeUserFromPush"]().then(() => {
                this.setBrowserNotificationText("Browser notification setting saved.")
                window.setTimeout(() => {
                    this.fadeOutBrowserNotificationText(500)
                }, 1500)
                addPageAction("PushNotificationSettingsUpdated", { "new_status": false, "old_status": true, "location": "settings-tab" })
            }).then(() => {
                window.setTimeout(() => {
                    checkbox.setDisabled(false)
                }, 2000)
            })
        }
        const attributes = { "checked": isChecked }
        addPageAction("FollowBrowserNotificationSettingsClicked", attributes)
    }

    private onFollowNotificationCheckboxChange(checkbox: StyledCheckboxInput): void {
        const attributes = { "checked": checkbox.getChecked() }
        addPageAction("FollowEmailSettingsClicked", attributes)
    }

    private onAppDevNotificationCheckboxChange(checkbox: StyledCheckboxInput): void {
        const attributes = { "checked": checkbox.getChecked() }
        addPageAction("AppDeveloperEmailsSettingsClicked", attributes)
    }

    private onNewsletterNotificationCheckboxChange(checkbox: StyledCheckboxInput): void {
        const attributes = { "checked": checkbox.getChecked() }
        addPageAction("NewsletterEmailsSettingsClicked", attributes)
    }
    /* eslint-enable @typescript-eslint/strict-boolean-expressions */

    private onOptInFlagDisplayCheckboxChange(checkbox: StyledCheckboxInput): void {
        const attributes = { "checked": checkbox.getChecked() }
        addPageAction("OptInFlagDisplayClicked", attributes)
    }

    private overrideOptInFlagDisplayField(field: IDjangoField): Field | undefined {
        if (field.name === "opt_in_flag_display") {
            return new StyledCheckboxInput({
                ...field,
                onChange: (field: StyledCheckboxInput) => this.onOptInFlagDisplayCheckboxChange(field),
                styles: new SettingsTabCheckboxFieldStyles(),
            })
        }
        return undefined
    }

    private setupPremiumSection(): void {
        const allowPremium = this.getField("allow_premium_private_shows")
        const allowPremiumElement = allowPremium?.getWidget() as HTMLSelectElement
        const standardPriceField = this.getField("private_show_tokens_per_minute")
        const standardPrivateAllowed = this.getField("allow_private_shows")
        if (standardPriceField === undefined || allowPremiumElement === undefined || standardPrivateAllowed === undefined) {
            return
        }
        const premiumTokensElement = this.getField("premium_private_show_tokens_per_minute")
        const spyTokensElement = this.getField("spy_on_private_show_tokens_per_minute")
        const premPriceElement = premiumTokensElement?.getWidget() as HTMLSelectElement
        const initialAllowed = premiumTokensElement?.getValue() !== ""
        allowPremiumElement.selectedIndex = initialAllowed ? 1 : 0
        // remove the first non-disabled option, 6 tokens will never be the premium price
        premPriceElement.remove(1)
        premPriceElement.options[0].text = "-"
        if (initialAllowed) {
            this.updatePremiumPrice(Number(standardPriceField.getValue()), premPriceElement)
        } else {
            this.updatePremiumPrice(0, premPriceElement)
        }
        this.updatePremiumAllowed(initialAllowed, premPriceElement)
        const updatePremiumHelpText = (spyPrice: number) => {
            if (allowPremium !== undefined) {
                if (spyPrice === 0) {
                    allowPremium.getHelpTextElement().innerText = i18n.premiumShowDisabled
                } else {
                    allowPremium.getHelpTextElement().innerText = i18n.premiumShowDesc
                }
            }
        }
        const updatePremiumToggle = () => {
            if (standardPrivateAllowed.getValue() === "False") {
                allowPremiumElement.selectedIndex = 0
                this.updatePremiumAllowed(false, premPriceElement)
                this.disableSelector(allowPremiumElement)
            } else {
                this.enableSelector(allowPremiumElement, false)
            }
        }
        updatePremiumToggle()
        updatePremiumHelpText(Number(spyTokensElement?.getValue() ?? 0))
        if (allowPremium !== undefined) {
            standardPriceField.setOnChange(() => {
                const allowed = allowPremium.getValue() === "True"
                if (allowed) {
                    this.updatePremiumPrice(Number(standardPriceField.getValue()), premPriceElement)
                }
            })
            standardPrivateAllowed.setOnChange(() => {
                updatePremiumToggle()
            })
            allowPremium.setOnChange(() => {
                const allowed = allowPremium.getValue() === "True"
                this.updatePremiumAllowed(allowed, premPriceElement)
                if (allowed) {
                    this.updatePremiumPrice(Number(standardPriceField.getValue()), premPriceElement)
                }
            })
        }
        if (spyTokensElement !== undefined) {
            spyTokensElement.setOnChange(() => {
                updatePremiumHelpText(Number(spyTokensElement.getValue()))
            })
        }
    }

    private updatePremiumPrice(standardPrice: number, premiumTokensElement: HTMLSelectElement): void {
        const currentPremiumPrice = Number(premiumTokensElement.value)
        let set = false
        for (let i = 0; i < premiumTokensElement.options.length; i++) {
            const elem = premiumTokensElement.options[i]
            // disable premium prices that are lower than or equal to the standard price
            if (Number(elem.value) <= standardPrice) {
                elem.disabled = true
            } else {
                elem.disabled = false
                // if no value is set, set the first available premium price, check the rest for the previous choice and select if available
                if (Number(elem.value) === currentPremiumPrice || !set) {
                    premiumTokensElement.selectedIndex = i
                }
                // we want to select the first available option because the previous choice may no longer available
                // previous choice will still be selected if later found
                set = true
            }
        }
        if (!set) {
            premiumTokensElement.selectedIndex = 0
        }
    }

    private updatePremiumAllowed(allow: boolean, premiumTokensElement: HTMLSelectElement): void {
        const premiumMinutesElement = this.getField("premium_private_show_minimum_minutes")?.getWidget() as HTMLSelectElement
        if (!allow) {
            // Select off Option
            premiumTokensElement.options[0].disabled = false
            premiumTokensElement.selectedIndex = 0
            this.disableSelector(premiumTokensElement)
            this.disableSelector(premiumMinutesElement)
        } else {
            this.enableSelector(premiumTokensElement, true)
            this.enableSelector(premiumMinutesElement, false)
        }
    }

    private disableSelector(selector: HTMLSelectElement): void {
        addColorClass(selector, "disabled")
        applyStyles(selector, {
            pointerEvents: "none",
            cursor: "not-allowed",
        })
        for (let i = 0; i < selector.options.length; i++) {
            selector.options[i].disabled = !selector.options[i].selected
        }
    }

    private enableSelector(selector: HTMLSelectElement, exceptFirst: boolean): void {
        removeColorClass(selector, "disabled")
        applyStyles(selector, {
            pointerEvents: "auto",
            cursor: "auto",
        })
        for (let i = 0; i < selector.options.length; i++) {
            selector.options[i].disabled = false
        }
        if (exceptFirst) {
            selector.options[0].disabled = true
        }
    }

    private setupFanClubSpying(): void {
        const fanClubSpySelect = this.getField("fan_club_spy_on_private_show_tokens_per_minute")
        const fanClubPrice = this.getField("fan_club_cost")
        const spyPrice = this.getField("spy_on_private_show_tokens_per_minute")
        if (fanClubSpySelect !== undefined && fanClubPrice !== undefined && spyPrice !== undefined) {
            const fanClubSpyHelpText = fanClubSpySelect.getHelpTextElement()
            new SettingsTabFieldStyles().styleHelpText(fanClubSpyHelpText)
            const selectElement = fanClubSpySelect.getWidget() as HTMLSelectElement
            this.updateFanSpyPrice(Number(spyPrice.getValue()), fanClubSpySelect.getWidget() as HTMLSelectElement)
            const toggleSelector = () => {
                const fanClubActive = Number(fanClubPrice.getValue()) > 0
                const spyShowsEnabled = Number(spyPrice.getValue()) > 0
                fanClubSpyHelpText.style.display = "block"
                fanClubSpyHelpText.innerText = t`'Free' allows Fan Club Members to spy on private shows for no token charge.`
                if (!spyShowsEnabled) {
                    fanClubSpyHelpText.innerText = t`Fan club discount for spying on private shows is disabled because spying is disabled.`
                } else if (!fanClubActive) {
                    fanClubSpyHelpText.innerText = t`Fan club discount for spying on private shows is disabled because your fan club is disabled.`
                }
                if (spyShowsEnabled && fanClubActive){
                    this.enableSelector(selectElement, false)
                    this.updateFanSpyPrice(Number(spyPrice.getValue()), fanClubSpySelect.getWidget() as HTMLSelectElement)
                } else {
                    this.disableSelector(selectElement)
                }
            }
            selectElement.options[0].text = i18n.disabled
            selectElement.options[1].text = i18n.free
            toggleSelector()
            fanClubPrice.setOnChange(() => {
                toggleSelector()
            })
            spyPrice.setOnChange(() => {
                this.updateFanSpyPrice(Number(spyPrice.getValue()), fanClubSpySelect.getWidget() as HTMLSelectElement)
                toggleSelector()
            })
        }
    }

    private updateFanSpyPrice(standardPrice: number, fanPriceSelector: HTMLSelectElement): void {
        let set = false
        let matchingPrice: number | undefined
        const currentIndex = fanPriceSelector.selectedIndex
        for (let i = fanPriceSelector.options.length - 1; i >= 0; i--) {
            const elem = fanPriceSelector.options[i]
            // disable spy prices that are greater than the standard price
            if (Number(elem.value) > standardPrice) {
                elem.disabled = true
            } else {
                elem.disabled = false
                if (Number(elem.value) === standardPrice) {
                    // save the index of the matching price
                    matchingPrice = i
                } else if (i === currentIndex) {
                    // select previous value if available
                    fanPriceSelector.selectedIndex = i
                    set = true
                }
            }
        }
        if (standardPrice !== 0 && !set) {
            // if previous value is no longer available, select the same price as the standard price
            fanPriceSelector.selectedIndex = matchingPrice ?? 0
        }
    }
}

export function getURLWithLanguageSubdomain(languageCode: string): string {
    const urlPath = `${window.location.pathname}${window.location.search}`
    let domain = window.location.hostname
    // remove www. from domain
    if (domain.indexOf("www.") !== -1) {
        domain = domain.replace("www.", "")
    }
    // check if there is 2 letter language subdomain through regex
    // and remove it
    const langSubdomain = domain.match(/^(?:[a-z]{2}|zh-hans|zh-hant)\./)
    if (langSubdomain) {
        domain = domain.replace(langSubdomain[0], "")
    }
    let newDomain =
        languageCode === "en"
            ? domain
            : `${languageCode?.toString()}.${domain}`
    newDomain = window.location.port
        ? `${newDomain}:${window.location.port}`
        : newDomain

    return `${window.location.protocol}//${newDomain}${urlPath}`
}


class SettingsTabFieldStyles extends FieldStyles {
    public styleLabel(label: HTMLElement): void {
        super.styleLabel(label)
        label.style.width = "100%"
        label.style.textAlign = "left"
        label.style.padding = "0"
        label.style.margin = "0 4px 6px"
    }

    public styleLabelContainer(labelContainer: HTMLElement): void {
        super.styleLabelContainer(labelContainer)
        labelContainer.style.width = "auto"
        labelContainer.style.minWidth = "345px"
        labelContainer.style.display = ""
        labelContainer.style.verticalAlign = "top"
        labelContainer.style.paddingLeft = "10px"
    }

    public styleField(field: HTMLElement): void {
        super.styleField(field)
        removeColorClass(field, "field")
        addColorClass(field, "fieldInput")
    }

    public styleHelpText(helpText: HTMLElement): void {
        super.styleHelpText(helpText)
        helpText.style.width = "auto"
        helpText.style.maxWidth = ""
    }

    public styleFieldContainer(fieldContainer: HTMLElement): void {
        super.styleFieldContainer(fieldContainer)
        fieldContainer.style.verticalAlign = "middle"
        fieldContainer.style.display = ""
    }
}

class SettingsTabSelectFieldStyles extends SettingsTabFieldStyles {
    public styleField(field: HTMLElement): void {
        super.styleField(field)
        field.style.width = ""
    }
}

class SettingsTabMultipleSelectFieldStyles extends SettingsTabSelectFieldStyles {
    public styleField(field: HTMLSelectElement): void {
        super.styleField(field)
        field.size = 10
        field.style.height = "160px"
        field.style.width = "100%"
        field.style.marginBottom = "10px"
    }

    public styleLabelContainer(labelContainer: HTMLElement): void {
        super.styleLabelContainer(labelContainer)
        labelContainer.style.width = "130px"
    }
}

class SettingsTabCheckboxFieldStyles extends SettingsTabFieldStyles {
    public styleFieldContainer(fieldContainer: HTMLElement): void {
        super.styleFieldContainer(fieldContainer)
        fieldContainer.style.paddingTop = "6px"
    }

    public styleField(field: HTMLElement): void {
        addColorClass(field, "fieldInput")
    }
}

class SettingsTabNotifictionCheckboxFieldStyles extends SettingsTabCheckboxFieldStyles {
    public styleLabelContainer(labelContainer: HTMLElement): void {
        super.styleLabelContainer(labelContainer)
        labelContainer.style.minWidth = "465px"
    }
}

class EmailFieldStyles extends SettingsTabFieldStyles {
    public styleField(field: HTMLElement): void {
        super.styleField(field)
        field.style.paddingLeft = "0"
        field.style.border = "none"
        field.style.background = "none"
    }
}

class HighlightedEmailFieldStyles extends EmailFieldStyles {
    public styleFieldContainer(fieldContainer: HTMLElement): void {
        super.styleFieldContainer(fieldContainer)
        addColorClass(fieldContainer, "highlightFieldContainer")
        fieldContainer.style.borderWidth = "1px"
        fieldContainer.style.borderStyle = "solid"
        fieldContainer.style.padding = "0 8px 6px 8px"
        fieldContainer.style.borderRadius = "4px"
    }

    public styleField(field: HTMLElement): void {
        super.styleField(field)
        addColorClass(field, "highlightField")
    }

    public styleLabel(label: HTMLElement): void {
        super.styleLabel(label)
        addColorClass(label, "highlightLabel")
    }
}

class AuthFieldStyles extends SettingsTabFieldStyles {
    public styleField(field: HTMLElement): void {
        super.styleField(field)
        field.style.display = "none"
    }
}
