import { MultiSelectDropdown } from "../../../cb/components/profile/multiSelectDropdown"
import { pageContext } from "../../../cb/interfaces/context"
import { currentSiteSettings } from "../../../cb/siteSettings"
import { EventRouter } from "../../events"
import { featureFlagIsActive } from "../../featureFlag"
import { addPageAction } from "../../newrelic"
import { isProfilePage } from "../../profilelib/profileRoot"
import { RoomTabs } from "../../roomTabs"
import { ContestStatsReactWrapper } from "../../theatermodelib/contestStats"
import { ShareTab } from "../../theatermodelib/shareTab"
import { i18n } from "../../translation"
import { parseQueryString, replaceUrlParams } from "../../urlUtil"
import { SatisfactionRating } from "../satisfactionRating"
import { privateSettingsClicked, spokenLanguageEditLinkClicked } from "../userActionEvents"
import { AppsTab, AppsTab2 } from "./appsTab"
import { BIO_TAB_STATE, BroadcastBioTab } from "./broadcastBioTab"
import { BroadcasterStatsTab } from "./broadcasterStatsTab"
import { GamesTab } from "./gamesTab"
import { MembershipsTab } from "./membershipsTab"
import { RoomFlagNotificationWrapper } from "./roomFlagNotifier"
import { SettingsTab } from "./settingsTab"
import { TokenStatsTab } from "./tokenStatsTab"
import type { StyledCheckboxInput } from "../../../cb/ui/fields"
import type { IInitialBroadcasterDossier } from "../../../entrypoints/broadcast"
import type { IProfileContext } from "../../../entrypoints/profile"
import type { IRoomDossier } from "../../roomDossier"

export enum BroadcasterTab {
    APPS = "apps",
    GAMES = "games",
    BIO = "Bio",
    SETTINGS = "settings",
    CONTEST_STATS = "Contest Stats",
    TOKEN_STATS = "token_stats",
    BROADCASTER_STATS = "broadcaster_stats",
    SHARE = "Share",
    MEMBERSHIPS = "memberships",
}

const BroadcasterTabQueryMapping: Record<BroadcasterTab, string> = {
    [BroadcasterTab.BIO]: "bio",
    [BroadcasterTab.SETTINGS]: "settings",
    [BroadcasterTab.CONTEST_STATS]: "contest",
    [BroadcasterTab.TOKEN_STATS]: "tokens",
    [BroadcasterTab.BROADCASTER_STATS]: "broadcasterStats",
    [BroadcasterTab.SHARE]: "share",
    [BroadcasterTab.MEMBERSHIPS]: "memberships",
    // APPS & GAMES tabs are empty since we only need the mapping for tabs on broadcaster's profile page
    [BroadcasterTab.APPS]: "",
    [BroadcasterTab.GAMES]: "",
}

export const broadcasterTabSwitched = new EventRouter<string>("broadcasterTabSwitched")

export class BroadcastRoomTabs extends RoomTabs {
    protected tabStorageKey = "selectedBroadcasterTab"
    // Tab page action disabled on page load
    private allowTabPageActions = false
    protected defaultTab: string
    protected activeTabName: BroadcasterTab

    constructor(roomName: string, private context: IProfileContext | IInitialBroadcasterDossier, roomDossier: IRoomDossier) {
        super()

        this.element.style.minHeight = "325px"
        const settingsTab = new SettingsTab(this.context.country)
        const broadcastBioTab = new BroadcastBioTab(roomName, context.isAgeVerified, context.canUploadContent)

        if (!isProfilePage()) {
            const appsTabClass = featureFlagIsActive("AppDirectoryV3") ? AppsTab2 : AppsTab
            this.addTab(BroadcasterTab.APPS, i18n.apps, new appsTabClass(roomDossier))
            this.addTab(BroadcasterTab.GAMES, i18n.games, new GamesTab())
        }
        const bioTab = this.addTab(BroadcasterTab.BIO, i18n.bioText, broadcastBioTab)
        const settingsAndPrivacyRoomTab = this.addTab(BroadcasterTab.SETTINGS, i18n.settingsAndPrivacy, settingsTab)

        const roomFlagNotificationWrapper = new RoomFlagNotificationWrapper({
            roomName: roomName,
            isBroadcastPage: !isProfilePage(),
            optInFlagDisplay: context.optInFlagDisplay ?? false,
            country: context.country,
        })
        if (roomFlagNotificationWrapper.shouldDisplayNotification) {
            this.tabBar.addChild(roomFlagNotificationWrapper)
            roomFlagNotificationWrapper.renderNotification({
                positioningEl: settingsAndPrivacyRoomTab.anchor.element,
                onActivated: () => {
                    if (this.activeTabName === BroadcasterTab.SETTINGS) {
                        const optInFlagDisplayField = settingsTab.getForm().getField("opt_in_flag_display")
                        if (optInFlagDisplayField !== undefined) {
                            const fieldInput = optInFlagDisplayField as StyledCheckboxInput
                            fieldInput.setChecked(true)
                        }
                    }
                },
                onCleanup: () => {
                    this.tabBar.removeChild(roomFlagNotificationWrapper)
                },
            })
        }

        if (!currentSiteSettings.isWhiteLabel) {
            this.addTab(BroadcasterTab.CONTEST_STATS, i18n.contestStatsText, new ContestStatsReactWrapper())
        }
        if (!this.context.isTestbed) {
            this.addTab(BroadcasterTab.TOKEN_STATS, i18n.tokenStats, new TokenStatsTab(context))
        }
        this.createStatsTab(context.statsDashboardEnabled)
        if (!currentSiteSettings.isWhiteLabel) {
            this.addTab(BroadcasterTab.SHARE, i18n.shareText, new ShareTab())
        }
        this.addTab(BroadcasterTab.MEMBERSHIPS, i18n.memberships, new MembershipsTab())

        this.tabBar.addChildBefore(new SatisfactionRating(context), this.tabsWrapper)

        this.defaultTab = this.setInitialTab()

        const expandableDropDownMenu = this.constructExpandableDropDownMenu()
        this.tabsWrapper.addChild(expandableDropDownMenu)
        this.tabsWrapper.element.dataset["paction"] = "RoomTabs"

        bioTab.anchor.element.classList.add("nooverlay") // for Lovense
        // eslint-disable-next-line @multimediallc/no-set-attribute
        bioTab.anchor.element.setAttribute("data-tab", "bio") // for Lovense

        privateSettingsClicked.listen(() => {
            this.makeTabActive(BroadcasterTab.SETTINGS, undefined, () => {
                const field = settingsTab.getForm().getField("allow_private_shows")
                if (field !== undefined) {
                    field.getWidget().focus()
                }
            })
        })

        // When the edit link in dismissible message is clicked, scroll to the spoken language field and show dropdown.
        spokenLanguageEditLinkClicked.listen(() => {
            this.makeTabActive(BroadcasterTab.BIO)
            broadcastBioTab.setState(BIO_TAB_STATE.EDIT_BIO, () => {
                broadcastBioTab.editBroadcastForm?.focusField("spoken_languages_strict")
                const spoken_languages_field = broadcastBioTab.editBroadcastForm?.getField("spoken_languages_strict")
                if (spoken_languages_field && spoken_languages_field instanceof MultiSelectDropdown) {
                    spoken_languages_field.checkboxesDropdown.showElement()
                }
            })
        })

        this.allowTabPageActions = true
    }

    protected setInitialTab(): string {
        let defaultTab = BroadcasterTab.BIO
        if (!isProfilePage()) {
            // load game tab to fire any existing GameSelection data, then switch to default tab
            this.makeTabActive(BroadcasterTab.GAMES)

            defaultTab = BroadcasterTab.APPS
            // immediately show Apps tab, then switch to correct tab
            this.makeTabActive(defaultTab)
        }

        // storedTab overrules defaultTab
        const tabQuery = parseQueryString(window.location.search)["tab"]
        const queryMappings: Record<string, BroadcasterTab> = {
            "settings_email": BroadcasterTab.SETTINGS,
            "settings": BroadcasterTab.SETTINGS,
            "bio": BroadcasterTab.BIO,
            "tokens": BroadcasterTab.TOKEN_STATS,
            "broadcasterStats": BroadcasterTab.BROADCASTER_STATS,
            "apps": BroadcasterTab.APPS,
            "contest": BroadcasterTab.CONTEST_STATS,
            "share": BroadcasterTab.SHARE,
            "memberships": BroadcasterTab.MEMBERSHIPS,
        }
        const queriedTab = tabQuery === undefined ? undefined : queryMappings[tabQuery]
        const storedTab = this.checkTabLocalStorage()
        if (queriedTab !== undefined) {
            this.makeTabActive(queriedTab)
        } else if (storedTab !== undefined) {
            this.makeTabActive(storedTab)
        }
        return defaultTab
    }

    protected makeTabActive(text: string, showArgument?: number, callback?: () => void): void {
        const activeTabName = this.activeTabName
        const activeTab = this.getActiveTab()
        if (activeTab !== undefined && activeTabName === BroadcasterTab.BIO && activeTabName !== text) {
            activeTab.instance.checkBeforeSwitch(() => {
                this.switchTab(activeTabName, text, showArgument, callback)
            })
        } else {
            this.switchTab(activeTabName, text, showArgument, callback)
        }

        if (this.allowTabPageActions && activeTabName !== text) {
            addPageAction("RoomTabClicked", { "tabName": text })
        }
    }

    private switchTab(active: string, next: string, showArgument?: number, callback?: () => void): void {
        super.makeTabActive(next, showArgument, callback)
        if (active !== next) {
            broadcasterTabSwitched.fire(active)
        }
    }

    protected navigate(text: string): void {
        super.navigate(text)
        const urlParam = BroadcasterTabQueryMapping[this.activeTabName]
        if (isProfilePage() && urlParam !== undefined && urlParam !== "") {
            // Change the `tab` url param if its present i.e. for profile page, without reloading the page.
            // This ensures we preserve the current tab when someone reloads the page having such a query param since
            // localstorage value won't be considered is such cases.
            // Localstorage using this.tabStorageKey takes precedence only for urls without the tab param
            replaceUrlParams(new Map<string, string>([["tab", urlParam]]))
        }
    }

    protected createStatsTab(isEnabled: boolean): void {
        if (isProfilePage() && pageContext.current.loggedInUser?.isStaff === true && isEnabled === true) {
            this.addTab(BroadcasterTab.BROADCASTER_STATS, i18n.broadcasterStats, new BroadcasterStatsTab())
        }
    }
}
