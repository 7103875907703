import { t } from "@lingui/macro"
import { usernameTitleCase } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { ExpandableDropDownMenu } from "../../cb/ui/expandableDropDownMenu"
import { makeResponsive } from "../../cb/ui/responsiveUtil"
import { Wrapper } from "../../cb/ui/wrapper"
import { profileLoaded, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { isProfilePage } from "../profilelib/profileRoot"
import { i18n } from "../translation"
import { GenderTabHandle } from "./genderTabHandle"
import { convertAllChildLinksToJoinOverlayAnchors } from "./joinOverlay"
import type { IProfileContext } from "../../entrypoints/profile"
import type { IRoomContext } from "../context"
import type { ListenerGroup } from "../events"

export const GENDER_TAB_HEIGHT = 27

export class GenderTabs extends Component {
    private readonly activeRoomTab: GenderTabHandle
    tabsRow: Component
    gendersCollapseTab: ExpandableDropDownMenu
    listenerGroup: ListenerGroup

    public get maxAvailableNonTabSpace(): number {
        const minimumTabsWidth = this.activeRoomTab.element.offsetWidth + this.gendersCollapseTab.element.offsetWidth
        return this.tabsRow.element.offsetWidth - minimumTabsWidth
    }

    constructor(roomName: string) {
        super()
        addColorClass(this.element, "genderTabs")

        addColorClass(this.element, colorClass.bgColor)
        addColorClass(this.element, colorClass.navigationAlt2BgImage)
        addColorClass(this.element, colorClass.navigationAlt2BgColor)
        addColorClass(this.element, colorClass.tabSectionBorder)
        applyStyles(this, {
            position: "relative",
            overflow: "visible",
            padding: "0 10px 0 32px",
            backgroundPosition: "bottom",
            boxSizing: "border-box",
            height: `${GENDER_TAB_HEIGHT}px`,
            marginTop: "11px",
            font: "1em 'UbuntuRegular',Arial,Helvetica,sans-serif",
        })
        this.element.classList.add("top-section")
        if (!isProfilePage()) {
            makeResponsive(this, 500, 1003, [
                { name: "paddingLeft", min: 8, max: 32 },
                { name: "paddingRight", min: 4, max: 16 },
            ])
        }
        this.tabsRow = new Wrapper()
        applyStyles(this.tabsRow, {
            position: "static",
            overflow: "hidden",
            height: `${GENDER_TAB_HEIGHT}px`,
        })
        // double wrapper to make follow tab dropdown visible in overflow hidden wrapper
        const wrapper = new Wrapper(this.tabsRow)
        applyStyles(wrapper, {
            overflow: "visible",
            position: "relative",
            height: `${GENDER_TAB_HEIGHT}px`,
            width: "100%",
        })

        this.activeRoomTab = new GenderTabHandle(`${i18n.roomTitleMessage(roomName)}`, undefined, true)
        this.activeRoomTab.element.title = `${usernameTitleCase(roomName)}'s Cam`
        this.activeRoomTab.element.dataset["pactionName"] = "ActiveRoom"
        this.tabsRow.addChild(this.activeRoomTab)

        this.createGenderTabs()
        this.gendersCollapseTab = new ExpandableDropDownMenu(new GenderTabHandle(ExpandableDropDownMenu.DOTDOTDOT_TEXT).element)
        this.styleCollapsedDropdown()
        this.gendersCollapseTab.element.style.fontSize = "13px"
        this.gendersCollapseTab.setSpacerWidth(10)
        this.tabsRow.addChild(this.gendersCollapseTab)
        this.gendersCollapseTab.element.classList.add("nooverlay")

        convertAllChildLinksToJoinOverlayAnchors(this.tabsRow.element)

        this.addChild(wrapper)

        const loadListener = isProfilePage() ? profileLoaded : roomLoaded
        loadListener.listen((context: IProfileContext | IRoomContext) => {
            this.setActiveRoomTab(context.dossier.room)
        })
    }

    private createGenderTabs() {
        this.tabsRow.addChild(new GenderTabHandle(t`WOMEN`, "/female-cams/"))
        this.tabsRow.addChild(new GenderTabHandle(t`MEN`, "/male-cams/"))
        this.tabsRow.addChild(new GenderTabHandle(t`COUPLES`, "/couple-cams/"))
        this.tabsRow.addChild(new GenderTabHandle(t`TRANS`, "/trans-cams/"))
    }

    public setActiveRoomTab(username: string): void {
        this.activeRoomTab.element.innerText = `${usernameTitleCase(username)}'s Cam`
        this.activeRoomTab.element.title = `${usernameTitleCase(username)}'s Cam`
    }

    private styleCollapsedDropdown(): void {
        applyStyles(this.gendersCollapseTab.dropDown, {
            width: "108px",
            paddingTop: "10px",
            paddingBottom: "5px",
        })
    }
}
