import { addColorClass } from "../../cb/colorClasses"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { followingEvent } from "../follow"
import { FollowUnfollowSection } from "../FollowUnfollowSection"
import type { IDeniedProfileContext } from "../../entrypoints/profile_denied"
import type { IFollowing } from "../follow"

export class DeniedContents extends Component<HTMLDivElement> {
    readonly roomContentsPadding = 4

    constructor(context: IDeniedProfileContext) {
        super()
        addColorClass(this.element, "BaseRoomContents")
        applyStyles(this.element, {
            position: "relative",
            display: "block",
            height: "auto",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "4px",
            padding: `${this.roomContentsPadding}px`,
            margin: "12px 0 17px 12px",
            overflow: "visible",
            boxSizing: "border-box",
            width: "calc(100% - 22px)",
        })

        const deniedNotice = document.createElement("div")
        deniedNotice.innerText = context.deniedMessage
        deniedNotice.dataset.testid = "denied-notice"
        applyStyles(deniedNotice, {
            fontFamily: "UbuntuRegular, Arial, Helvetica, sans-serif",
            fontSize: "12px",
            fontWeight: "bold",
            margin: "13px 0",
        })
        this.element.appendChild(deniedNotice)

        if (context.isFollowing) {
            const followDiv = document.createElement("div")
            applyStyles(followDiv, {
                position: "absolute",
                top: "16px",
                right: "16px",
                height: "20px",
                width: "150px",
            })
            this.element.appendChild(followDiv)
            const followUnfollowSection = new FollowUnfollowSection(this.element, context.roomName)
            followDiv.appendChild(followUnfollowSection.element)
            followUnfollowSection.showUnfollow(context.followNotificationFrequency)
            followingEvent.listen((followingEvent: IFollowing) => {
                if (followingEvent.roomName === context.roomName) {
                    if (!followingEvent.following) {
                        followUnfollowSection.hideElement()
                    }
                }
            })
        }
    }
}
