import { addColorClass } from "../../cb/colorClasses"
import { TransparentCheckbox } from "../../cb/components/toggle"
import { BaseNotificationsModal } from "../../cb/ui/notificationsModal"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { postCb } from "../api"
import { createDivotTop } from "../divot"
import { applyStyles } from "../DOMutils"
import { setEmailNotificationSetting } from "../follow"
import { addPageAction } from "../newrelic"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import type { INotificationTimeoutLocalStorageKeys } from "../../cb/ui/notificationsModal"

export interface INotificationPopupSettings {
    emailSubscribed: boolean,
    emailAddress: string,
    emailValidated: boolean,
}

const ConfirmButton = (text: string, onClick: () => void) => {
    const confirmButtonStyle: CSSX.Properties = {
        padding: "7px",
        borderRadius: "4px",
        borderWidth: "1px",
        borderStyle: "solid",
        boxSizing: "border-box",
        position: "relative",
        display: "inline-block",
        cursor: "pointer",
    }
    return <span style={confirmButtonStyle} onClick={onClick} colorClass="button">{text}</span>
}

const NotificationsModalSavedStorageKey = "notificationModalOptionsSaved"
const EmailNotificationModalLocalStorageKeys: INotificationTimeoutLocalStorageKeys = {
    numTimeoutsKey: "numPushNotificationTimeouts2",
    timeoutDateKey: "pushNotificationTimeout2",
}

export class EmailNotificationsModal extends BaseNotificationsModal {
    private bodyDiv: HTMLDivElement
    private settingsOptionsDiv: HTMLDivElement
    private emailCheckbox: TransparentCheckbox
    private browserCheckbox: TransparentCheckbox
    private browserTextSpan: HTMLSpanElement
    private emailInput: HTMLInputElement
    private emailVerificationDiv: HTMLDivElement
    private emailDiv: HTMLDivElement
    private browserDiv: HTMLDivElement
    private prompt: HTMLSpanElement
    private emailCheckboxDiv: HTMLDivElement
    private confirmButton: HTMLSpanElement
    private notNowButton: HTMLSpanElement
    private buttonsDiv: HTMLDivElement
    private currentlyEmailSubscribed = false
    private emailAddress = ""
    private emailIsValidated: boolean
    private verificationEmailSent = false
    private emailOptHidden = false
    private browserOptHidden = false
    private submitting = false

    constructor(props = EmailNotificationModalLocalStorageKeys) {
        super(props, true)
    }

    protected initUI(): void {
        super.initUI()

        const modalStyle: CSSX.Properties = {
            position: "absolute",
            width: "304px",
            top: "30px",
            right: "0px",
            borderStyle: "solid",
            zIndex: 1001,
        }
        const bodyDivStyle: CSSX.Properties = {
            position: "relative",
            width: "100%",
            padding: "7px",
            fontSize: "14px",
            fontFamily: "UbuntuRegular, Arial, Helvetica, sans-serif",
            boxSizing: "border-box",
        }
        const promptTextStyle: CSSX.Properties = {
            position: "relative",
            lineHeight: "22px",
        }
        const emailOptionDivStyle: CSSX.Properties = {
            position: "relative",
            margin: "4px 0px",
        }
        const emailInputStyle: CSSX.Properties = {
            position: "relative",
            height: "22px",
            width: "278px",
            padding: "5px",
            margin: "8px 0px",
            fontFamily: "UbuntuRegular, Arial, Helvetica, sans-serif",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            display: "none",
        }
        const popularBadgeStyle: CSSX.Properties = {
            position: "relative",
            fontFamily: "UbuntuBold, Arial, Helvetica, sans-serif",
            fontSize: "10px",
            padding: "2px",
            borderRadius: "2px",
            marginLeft: "6px",
            bottom: "1px",
        }
        const buttonsDivStyle: CSSX.Properties = {
            position: "relative",
            marginBottom: "4px",
            cursor: "pointer",
        }
        const notNowButtonStyle: CSSX.Properties = {
            position: "relative",
            marginLeft: "12px",
            cursor: "pointer",
        }
        const verifyEmailLinkStyle: CSSX.Properties = {
            cursor: "pointer",
            marginBottom: "6px",
            paddingLeft: "2px",
            fontSize: "12px",
        }
        const emailMessageStyle: CSSX.Properties = {
            marginBottom: "6px",
            paddingLeft: "2px",
            display: "none",
        }
        const verifyEmailTextStyle: CSSX.Properties = {
            marginBottom: "6px",
            paddingLeft: "2px",
            display: "none",
            fontSize: "12px",
        }
        const browserMessageStyle: CSSX.Properties = {
            marginTop: "6px",
            paddingLeft: "2px",
            display: "none",
        }

        const verificationLinkClick = () => {
            postCb("emailvalidation/resend/", {}).then(() => {
                verifyEmailLink.style.display = "none"
                checkInboxMessage.style.display = "block"
                this.verificationEmailSent = true
            }).catch(() => {
                modalAlert(i18n.emailSendErrorMessage)
            })
        }

        // Email option has either changed or is left on
        const submitEmailForm = (emailIsChecked: boolean, newEmail = ""): Promise<string> => {
            // Submit email address with email option, if verification email for inputted email address hasn't already been sent
            if (emailIsChecked && !this.emailIsValidated && (!this.verificationEmailSent || this.emailAddress !== newEmail)) {
                return setEmailNotificationSetting(emailIsChecked, newEmail)
            }
            // Submit email option only, or email stayed the same and verification email is already sent
            return setEmailNotificationSetting(emailIsChecked)
        }

        const showBrowserErrorModal = (response?: XMLHttpRequest) => {
            if (response === undefined || response.status === 400) {
                modalAlert(`${i18n.browserSubmitErrorMessage} ${i18n.browserSubmitErrorMessagePt2}`)
            } else {
                window.localStorage.removeItem(this.numTimeoutsLocalStorageKey)
                window.localStorage.removeItem(this.timeoutDateLocalStorageKey)
            }
        }

        const showEmailErrorAndCheckBrowserOpt = (errMsg: string) => {
            // Show email option error, then handle browser option selection with either a 2nd error message or a success indicator
            emailErrorMessage.textContent = errMsg
            emailErrorMessage.style.display = "block"
            if (!this.browserOptHidden && this.browserCheckbox.isChecked()) {
                this.subscribeToBrowserPush(
                    () => {
                        // Visually indicate successful browser opt-in
                        addColorClass(this.browserCheckbox.element, "greenCheckbox")
                        this.browserDiv.style.opacity = "0.5"
                        this.browserTextSpan.style.cursor = ""
                        this.browserCheckbox.element.style.cursor = ""
                        this.browserOptHidden = true
                        this.browserCheckbox.disable()
                        this.browserTextSpan.textContent = i18n.browserNotificationEnabled
                        this.browserTextSpan.onclick = () => {}
                    },
                    () => {
                        browserErrorMessage.style.display = "block"
                    },
                )
            }
            this.submitting = false
        }

        const handleEmailAndBrowserOptions = (emailIsChecked: boolean, emailInputRequired: boolean, emailOptChanged: boolean) => {
            const newEmail = emailIsChecked ? this.emailInput.value.trim().toLowerCase() : this.emailAddress
            // Handle empty email address input if it's required
            if (emailInputRequired && newEmail === "") {
                showEmailErrorAndCheckBrowserOpt(i18n.addEmailAddress)
            } else if (this.verificationEmailSent && !emailOptChanged && this.emailAddress === newEmail) {
                // No need for POST if no changes were made and verification link has been clicked
                this.showSuccessScreen(false)
            } else {
                submitEmailForm(emailIsChecked, newEmail).then((showVerifyMsg: string) => {
                    // Email option submission successful
                    this.showSuccessScreen(emailInputRequired && showVerifyMsg === "true")
                    if (!this.browserOptHidden && this.browserCheckbox.isChecked()) {
                        this.subscribeToBrowserPush(
                            () => {},
                            (response?: XMLHttpRequest) => {
                                showBrowserErrorModal(response)
                            },
                        )
                    }
                }).catch((errStr: string) => {
                    // Email option submission error
                    showEmailErrorAndCheckBrowserOpt(errStr)
                })
            }
        }

        const handleBrowserOption = () => {
            // Browser option changed
            if (!this.browserOptHidden && this.browserCheckbox.isChecked()) {
                this.subscribeToBrowserPush(
                    () => {
                        this.showSuccessScreen(false)
                    },
                    (response?: XMLHttpRequest) => {
                        showBrowserErrorModal(response)
                        this.submitting = false
                        this.close()
                    })
            } else {
            // Neither options changed
                this.showSuccessScreen(false)
            }
        }

        const submitForm = () => {
            if (this.submitting) {
                return
            }
            this.submitting = true
            const emailIsChecked = this.emailCheckbox.isChecked()
            const emailInputRequired = emailIsChecked && !this.emailIsValidated
            const emailOptChanged = emailIsChecked !== this.currentlyEmailSubscribed
            if (!this.emailOptHidden && (emailInputRequired || emailOptChanged)) {
                // Handle email option change and/or email address input
                handleEmailAndBrowserOptions(emailIsChecked, emailInputRequired, emailOptChanged)
            } else {
                // No email option change nor email address required
                handleBrowserOption()
            }
        }

        const notNowHandler = () => {
            this.onDenyClick()
            this.close()
        }

        const divot = createDivotTop("", "", "240px", 1)
        addColorClass(divot, "divot")
        addColorClass(divot, "divotTop")
        this.emailInput = <input style={emailInputStyle} colorClass="textInput" onInput={() => {
            if (this.emailAddress !== "") {
                this.emailVerificationDiv.style.display = this.emailInput.value === this.emailAddress ? "inline-block" : "none"
            }
        }}/>
        this.emailInput.placeholder = i18n.emailAddressText
        const checkboxStyle: CSSX.Properties = {
            top: "4px",
            marginRight: "6px",
            cursor: "pointer",
        }
        this.emailInput.dataset["pactionName"] = "Email Input"
        const isBrowserNotificationDisabledByUser = window["Notification"]?.permission === "denied"
        this.emailCheckbox = new TransparentCheckbox(16)
        this.browserCheckbox = new TransparentCheckbox(16, false, () => {
            if (isBrowserNotificationDisabledByUser) {
                modalAlert("Browser Notifications are disabled")
                this.browserCheckbox.setCheckedDirectly(false)
            }
        })

        applyStyles(this.emailCheckbox, checkboxStyle)
        applyStyles(this.browserCheckbox, checkboxStyle)
        addEventListenerPoly("click", this.emailCheckbox.element, () => {
            if (!this.emailCheckbox.isChecked()) {
                this.emailInput.style.display = "none"
            } else if (!this.emailIsValidated) {
                this.emailInput.style.display = "block"
                this.emailInput.focus()
            }
            addPageAction("FollowEmailSettingsClicked", { "checked": this.emailCheckbox.isChecked() })
        })
        this.browserTextSpan = <span style={{ cursor: "pointer" }} onClick={() => {
            if (isBrowserNotificationDisabledByUser) {
                modalAlert("Browser notifications are disabled")
                return
            }
            this.browserCheckbox.element.click()
        }}>{i18n.browserNotificationLabel}</span>
        const verifyEmailLink = <div style={verifyEmailLinkStyle} colorClass="verifyLink" onClick={verificationLinkClick}>{i18n.resendVerificationEmail}</div>
        const checkInboxMessage = <div style={verifyEmailTextStyle}>{i18n.checkYourEmailToVerifyText}</div>
        const emailErrorMessage = <div style={emailMessageStyle} colorClass="errorMessage"></div>
        const browserErrorMessage = <div style={browserMessageStyle} colorClass="errorMessage">{i18n.browserSubmitErrorMessage}</div>
        this.emailVerificationDiv = <div style={{ display: "none" }}>{verifyEmailLink}{checkInboxMessage}</div>
        this.emailCheckboxDiv = <div style={emailOptionDivStyle}>
            {this.emailCheckbox.element}
            <div style={{ width: "140px", display: "inline-block", cursor: "pointer" }} onClick={() => {this.emailCheckbox.element.click()}}>
                <span>{i18n.emailLabel}</span>
                <span style={popularBadgeStyle} colorClass="popularBadge">{i18n.popularCAPS}</span>
            </div>
        </div>
        this.emailDiv = <div style={{ position: "relative" }}>
            {this.emailCheckboxDiv}
            <div>{this.emailInput}</div>
            {this.emailVerificationDiv}
            {emailErrorMessage}
        </div>
        this.browserDiv = <div style={{ position: "relative", display: "none", marginBottom: "12px" }}>
            {this.browserCheckbox.element}
            {this.browserTextSpan}
            {browserErrorMessage}
        </div>
        this.prompt = <span style={promptTextStyle}>{i18n.notifyWhenBroadcastersOnlineMessage}:</span>
        this.notNowButton = <span style={notNowButtonStyle} onClick={notNowHandler}>{i18n.notNowText}</span>
        this.confirmButton = ConfirmButton(i18n.saveNotificationSettings, submitForm)
        this.buttonsDiv = <div style={buttonsDivStyle}>
            {this.confirmButton}
            {this.notNowButton}
        </div>
        this.settingsOptionsDiv = <div>
            {this.prompt}
            {this.emailDiv}
            {this.browserDiv}
            {this.buttonsDiv}
        </div>
        this.bodyDiv = <div style={bodyDivStyle}>{this.settingsOptionsDiv}</div>
        this.element = <div style={modalStyle} id="FollowSettingsModal" colorClass="tooltip">
            {this.bodyDiv}
            {divot}
        </div>
    }

    public canRequestPermission(): boolean {
        // User has not previously clicked "Save" on this popup, and is currently not in timeout from clicking "Not Now"
        return super.canRequestPermission() && window.localStorage.getItem(NotificationsModalSavedStorageKey) === null
    }

    setOriginalState(settings: INotificationPopupSettings): void {
        // Make options reflect true state
        this.currentlyEmailSubscribed = settings.emailSubscribed
        this.emailIsValidated = settings.emailValidated
        this.emailAddress = settings.emailAddress
        this.emailCheckbox.setChecked(this.currentlyEmailSubscribed)
        this.emailInput.value = this.emailAddress
        if (this.emailAddress !== "" && !this.emailIsValidated) {
            this.emailVerificationDiv.style.display = "inline-block"
        }
        // If email opted-in, hide option if email validated, otherwise show email address input
        if (this.currentlyEmailSubscribed) {
            if (this.emailIsValidated) {
                this.emailDiv.style.display = "none"
                this.emailOptHidden = true
            } else {
                this.emailInput.style.display = "block"
            }
        }
    }

    public showBrowserOption(): void {
        this.browserDiv.style.display = "block"
        this.browserOptHidden = false
    }

    private showSuccessScreen(showVerifyEmailMsg: boolean): void {
        this.settingsOptionsDiv.style.display = "none"
        const messageStyle: CSSX.Properties = {
            marginBottom: "16px",
            lineHeight: "22px",
        }
        let text: HTMLDivElement
        if (showVerifyEmailMsg) {
            text = <div style={messageStyle} dangerouslySetInnerHTML={{ __html: i18n.emailSavedAndVerifyText }} />
        } else {
            text = <div style={messageStyle}>{i18n.preferencesSavedText}</div>
        }
        this.bodyDiv.appendChild(<div>{text}{ConfirmButton(i18n.close, () => {this.close()})}</div>)
        this.submitting = false

        // Don't show this popup again
        window.localStorage.setItem(NotificationsModalSavedStorageKey, "true")
    }
}
