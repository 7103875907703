import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import type { IRoomContext } from "./context"

export abstract class RoomTabButton extends Component<HTMLDivElement> {
    protected iconContainer = document.createElement("span")
    protected textContainer = document.createElement("span")
    protected roomName: string

    constructor(roomName?: string) {
        super()
        this.element.appendChild(this.iconContainer)
        this.element.appendChild(this.textContainer)
        this.hideElement()
        this.element.style.width = "auto"
        this.element.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        this.element.style.fontSize = "12px"
        this.element.style.padding = "3px 8px 2px"
        this.element.style.position = "relative"
        this.element.style.top = "-4px"
        this.element.style.right = "1px"
        this.element.style.cssFloat = "right"
        this.element.style.borderRadius = "3px"
        this.element.style.cursor = "pointer"
        this.element.style.marginRight = "5px"
        this.element.style.height = "15px"
        this.element.style.lineHeight = "1.4"

        if (roomName !== undefined) {
            this.setRoomName(roomName)
        }

        roomLoaded.listen((context: IRoomContext) => {
            this.setRoomName(context.chatConnection.room())
        })
    }

    protected setRoomName(roomName: string): void {
        this.roomName = roomName
    }
}
